import { ImageNode } from 'iq-product-render';
import React, { useMemo } from 'react';
import { ShopBackground, ShopImage } from '../../../../../../../shop-api-client';
import {
  CartImageNode,
  CartImageNodeCrop,
  CartImageNodeReq,
  CartPrintProduct,
  CartPrintProductReq,
  ImageTone,
} from '../../../../../../../shop-api-client/models/Cart';
import { ShopImageNodeWithReqType } from '../../../../../../../shop-api-client/models/ShopProductNodes';
import { selectBackgroundsMap } from '../../../../../../redux/selectors/background.selectors';
import {
  selectConfiguration,
  selectEditItemAvailableImages,
  selectIsStepCompleted,
} from '../../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../../../redux/store';
import { getImageNodesStep, getProductToNodeKey } from '../../../utils';
import ImageSelection from '../../ImageSelection';
import { selectCart } from '../../../../../../redux/selectors/cart.selectors';

interface Props {
  catalogNode: ShopImageNodeWithReqType;
  editNode: CartImageNodeReq | CartImageNode;
  editProduct: CartPrintProductReq | CartPrintProduct;
  flipSingleImageNode: boolean;
  selectAndSaveImage(
    image: ShopImage,
    imageNode: CartImageNodeReq | CartImageNode,
    catalogNode: ImageNode,
    background?: ShopBackground,
    imageTone?: ImageTone,
    userCrop?: CartImageNodeCrop,
  ): void;
  isInvalidBuyModeImage?: boolean;
}

/**
 * Primary image node editor for a single node product.
 */
const ImageNodeSingle = ({
  catalogNode,
  editNode,
  editProduct,
  flipSingleImageNode,
  selectAndSaveImage,
  isInvalidBuyModeImage,
}: Props) => {
  const stepName = useMemo(() => getImageNodesStep(editProduct.id!), [editProduct]);

  // Selectors
  const backgroundsMap = useAppSelector(selectBackgroundsMap);
  const { shopFavorites } = useAppSelector(selectCart);
  const { editStep } = useAppSelector(selectConfiguration);
  const availableImages = useAppSelector(selectEditItemAvailableImages);
  const {
    images,
    settings: { disableCropping },
  } = useAppSelector(state => selectGallery(state));
  const completed = useAppSelector(state => selectIsStepCompleted(state, stepName));

  // Misc variables
  const nodeImages =
    availableImages.nodes[getProductToNodeKey(editProduct.priceSheetItemID, catalogNode.id)];

  const selectImage = (image: ShopImage, background?: ShopBackground, crop?: CartImageNodeCrop) => {
    selectAndSaveImage(image, editNode, catalogNode, background, 'original', crop);
  };

  const initialImage = editNode?.imageInternalName
    ? images[editNode?.imageInternalName]
    : undefined;

  const singleNodeImage = nodeImages?.length === 1;
  const lockedImage = singleNodeImage && shopFavorites.some(f => !f.imageName);

  return (
    <ImageSelection
      availableImages={nodeImages}
      catalogNode={catalogNode}
      completed={completed}
      disableCrop={!!disableCropping}
      flipSingleImageNode={flipSingleImageNode}
      imageNode={editNode}
      imageRequirementType={catalogNode.imageRequirementType}
      initialBackground={backgroundsMap[editNode?.backgroundID || '']}
      initialImage={initialImage}
      isActive={!completed || editStep === stepName}
      isDisabled={false}
      lockedImage={lockedImage}
      onSelect={selectImage}
      showContinue
      stepName={stepName}
      isInvalidBuyModeImage={isInvalidBuyModeImage}
    />
  );
};

export default ImageNodeSingle;
