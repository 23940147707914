import { CatalogProductWithMetadata } from 'iq-product-render';
import {
  api,
  ShopPackage,
  ShopProduct,
  ShopProductPrint,
  ShopProductNonPrint,
} from '../../../shop-api-client';
import { setRendererProductMap } from '../slices/catalog.slice';
import { PromiseThunk } from '../store';

type ShopProductItem = ShopProductPrint | ShopProductNonPrint;

export const getRendererProducts =
  (shopItems: (ShopProduct | ShopPackage)[]): PromiseThunk<CatalogProductWithMetadata[] | null> =>
  async (dispatch, getState) => {
    try {
      const {
        visitor: { currentVisitKey },
        catalog: { rendererProductMap },
      } = getState();

      const itemsToRender: ShopProductItem[] = [];
      let renderedItems: CatalogProductWithMetadata[] = [];

      for (const shopItem of shopItems) {
        if (shopItem.type === 'package' || shopItem.type === 'package-byop') {
          const filtered = shopItem.availableProducts.filter(
            p => p.type === 'product' && !rendererProductMap[p.catalogProductID],
          ) as ShopProductPrint[];
          itemsToRender.push(...filtered);
        }
        if (
          (shopItem.type === 'product' || shopItem.type === 'nonPrintProduct') &&
          !rendererProductMap[shopItem.catalogProductID]
        ) {
          itemsToRender.push(shopItem);
        }
      }

      if (itemsToRender.length) {
        renderedItems = await Promise.all(
          itemsToRender.map(i => api.getCatalogProduct(currentVisitKey!, i)),
        );
        dispatch(setRendererProductMap(renderedItems));
      }

      return { valid: true, payload: renderedItems, error: null };
    } catch (error) {
      return { valid: false, payload: null, error };
    }
  };
