import React, { useEffect } from 'react';
import { Route, Switch, useHistory, matchPath } from 'react-router-dom';
import Landing from './app/features/Landing';
import NoKeyInUrl from './app/features/NoKeyInUrl';
import Unsubscribe from './app/features/Unsubscribe';
import ProtectedApp from './app/ProtectedApp';
import DigitalDownloads from './app/features/DigitalDownloads';
import FacialRecognition from './app/features/FacialRecognition';
import { IS_ACCOUNT_KEY } from './app/shared/constants/regex.constants';

const PAGE_NAME_MAP: Record<string, string> = {
  //facial-recognition
  '/find-me/:galleryID/:token?': 'findMe',
  //cart
  '/downloads/:token': 'downloads',
  //utility
  '/unsubscribe/:queueMessageID': 'unsubscribe',
  '/:key/carts': 'carts',
  //multigallery
  '/:key/galleries/available': 'multigallery/available',
  '/:key/galleries/find': 'multigallery/search',
  '/:key/galleries/:nextKey/login': 'login/multigallery',
  //checkout
  '/:key/checkout/:checkoutID/confirmation': 'checkout/confirmation',
  '/:key/checkout/:checkoutID': 'checkout',
  //photos
  '/:key/photos/all/photo/:photoID/(bg)?/:backgroundID?': 'photos/all/photo',
  '/:key/photos/all': 'photos/all',
  '/:key/photos/:groupID/photo/:photoID/(bg)?/:backgroundID?': 'photos/group/photo',
  '/:key/photos/:groupID': 'photos/group',
  '/:key/photos/favorites': 'photos/favorite',
  //product/package
  '/:key/configure/package/:packageID': 'shop/package/configure',
  '/:key/configure/product/:productID': 'shop/product/configure',
  '/:key/configure/wizard/:packageID/backgrounds': 'shop/package/configure/wizard/bg',
  '/:key/configure/wizard/:packageID/byop-selection': 'shop/package/configure/wizard/byop',
  '/:key/configure/wizard/:packageID/customize/:editSubItemID?':
    'shop/package/configure/wizard/customize',
  '/:key/configure/wizard/:packageID/image-options': 'shop/package/configure/wizard/addon',
  '/:key/configure/wizard/:packageID/summary': 'shop/package/configure/wizard/summary',
  '/:key/configure/wizard/:packageID': 'shop/package/configure/wizard',
  '/:key/shop/:categoryID/product/:productID': 'shop/product',
  '/:key/shop': 'shop',
  //yearbook
  '/:key/yearbook/:poseType': 'yearbook/pose',
  '/:key/yearbook': 'yearbook',
};

const usePageViewEvent = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      let pageTitle = 'unknown';
      if (matchPath(pathname, { path: '/:key', exact: true })) {
        const isAccount = IS_ACCOUNT_KEY.test(pathname.slice(1).toUpperCase());
        pageTitle = isAccount ? 'login/lookup' : 'login/gallery';
      } else {
        for (const pagePath of Object.keys(PAGE_NAME_MAP)) {
          const match = matchPath(pathname, pagePath);
          if (match) {
            pageTitle = PAGE_NAME_MAP[pagePath];
            break;
          }
        }
      }
      if (window.gtag && pathname !== '/') {
        window.gtag('set', 'page_title', pageTitle);
      }
    });

    return unlisten;
  }, [history]);
};
// if `/key`
// 1. Routes to Landing first
// 2. renders account or auth landing based on key type
// 3. if gallery/subject gallery key, we authorize and redirect user to either photos/shop
// 4. if auth fails, we redirect to login page

const App = () => {
  usePageViewEvent();
  return (
    <Switch>
      <Route path="/download/:token" component={DigitalDownloads} />
      <Route path="/find-me/:galleryID/:token?" component={FacialRecognition} />
      <Route path="/unsubscribe/:queueMessageID" component={Unsubscribe} />
      <Route path="/:key" exact component={Landing} />
      <Route path="/:key" component={ProtectedApp} />
      <Route path="/" component={NoKeyInUrl} />
    </Switch>
  );
};

export default App;
