import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Link,
  Slide,
  Spinner,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { selectAccount } from '../../redux/selectors/account.selectors';
import {
  selectAllCartsItemCount,
  selectCartMap,
  selectShopProductsInCarts,
} from '../../redux/selectors/cart.selectors';
import { selectSummaryFinancials } from '../../redux/selectors/financials.selectors';
import { selectGallery, selectGalleryMap } from '../../redux/selectors/gallery.selectors';
import {
  selectPriceSheet,
  selectPSproductsByType,
} from '../../redux/selectors/priceSheet.selectors';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { getCartFinancials } from '../../redux/thunks/cart.thunks';
import { getRendererProducts } from '../../redux/thunks/catalog.thunks';
import { createCheckout } from '../../redux/thunks/checkout.thunks';
import ButtonLink from '../../shared/components/ButtonLink';
import StickyMobileSection from '../../shared/components/Mobile/StickyMobileSection';
import RoundedBorderBox from '../../shared/components/RoundedBorderBox';
import { NAVIGATION_Z_INDEX } from '../../shared/constants';
import {
  CONTAINER_MAX_WIDTH,
  CONTINUE_TO_CHECKOUT,
  NO_ITEMS_IN_CART,
} from '../../shared/constants/carts.constants';
import { SHOP_ALL_CATEGORIES } from '../../shared/constants/labels.constants';
import useIsElemInView from '../../shared/hooks/useIsElemInView';
import useWindowSize from '../../shared/hooks/useWindowSize';
import { Params } from '../../shared/types/router';
import { formatCurrency, GAItem, getGAItems } from '../../shared/utils';
import OrderSummary from '../Checkout/OrderSummary';
import ProductCarousel from '../Products/ProductCarousel';
import Cart from './Cart/Cart';
import SignInBanner from './Cart/SignInBanner';
import YearbookAlert from './Cart/YearbookAlert';
import CartsModal from './CartsModal';

const Carts = () => {
  // Redux selectors
  const { categories, sortedCategoryIDs } = useSelector(selectPriceSheet);
  const { packages, productsAndPrints } = useSelector(selectPSproductsByType);
  const { isPreOrder, yearbookDueDate, yearbookPoses } = useSelector(selectGallery);
  const { yearbookSelections } = useSelector(selectGallery);
  const { accountID, currency } = useSelector(selectAccount);
  const cartMap = useSelector(selectCartMap);
  const galleryMap = useSelector(selectGalleryMap);
  const itemCount = useAppSelector(selectAllCartsItemCount);
  const shopProducts = useSelector(selectShopProductsInCarts);
  const summaryFinancials = useSelector(selectSummaryFinancials);

  // State
  const [checkingOut, setCheckingOut] = useState(false);
  const [loading, setLoading] = useState(true);
  const [possibleCheckouts, setPossibleCheckouts] = useState<string[][]>();
  const [showModal, setShowModal] = useState(false);

  // Hooks
  const { key } = useParams<Params>();
  const { width } = useWindowSize();
  const checkoutBtnRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const elemInView = useIsElemInView(checkoutBtnRef.current);
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const yearbookSelection1 = yearbookSelections?.yearbookSelection1;
  const yearbookSelection2 = yearbookSelections?.yearbookSelection2;

  const items = intl.formatMessage(
    {
      id: 'cart.items',
      defaultMessage: '{itemCount} {items}',
    },
    { itemCount, items: itemCount > 1 ? 'items' : 'item' },
  );
  const reviewCart = intl.formatMessage(
    {
      id: 'cart.reviewYourCart',
      defaultMessage: 'Review your cart {itemCount}',
    },
    { itemCount: isMobile ? '' : `(${itemCount})` },
  );

  const gaItems: GAItem[] = getGAItems(cartMap, accountID);

  // Google Analytics view_cart
  useEffect(() =>
    window.gtag('event', 'view_cart', {
      items: gaItems,
    }),
  );

  useEffect(() => {
    if (!loading) {
      return;
    }

    const init = async () => {
      // Load the financials whenever we load this component:
      await dispatch(getCartFinancials());

      // Load renderer products for multi-node print previews
      await dispatch(getRendererProducts(Object.values(shopProducts)));

      setLoading(false);
    };

    init();
  }, [key, dispatch, loading, shopProducts]);

  const showYearbookAlert =
    (!!yearbookDueDate && yearbookPoses === 1 && !yearbookSelection1) ||
    (yearbookPoses === 2 && (!yearbookSelection1 || !yearbookSelection2));

  const handleCheckout = async (visitKeys?: string[]) => {
    setCheckingOut(true);
    const result = await dispatch(createCheckout(visitKeys));
    if (!result) {
      setCheckingOut(false);
      return;
    }
    if (result.checkout) {
      // Checkout automatically created, redirect user:
      history.push(`/${key}/checkout/${result.checkout.id}`);

      //Google Analytics begin_checkout events.
      window.gtag('event', 'begin_checkout', {
        items: gaItems,
      });
    } else {
      setShowModal(true);
      // Checkout could not be automatically created, show possible checkouts:
      setPossibleCheckouts(result.combinations);
      setCheckingOut(false);
    }
  };

  const handleClickCheckout = () => {
    if (!checkingOut) {
      handleCheckout();
    }
  };

  const renderMobileCheckoutBtn = () => (
    <Button
      disabled={checkingOut}
      fontSize="sm"
      isLoading={checkingOut}
      loadingText={CONTINUE_TO_CHECKOUT}
      marginTop={4}
      onClick={handleClickCheckout}
      width="100%"
    >
      {CONTINUE_TO_CHECKOUT}
    </Button>
  );

  const renderCarts = () => {
    if (loading) {
      return (
        <Center width="100%" marginRight={{ base: 0, lg: '55px' }}>
          <Spinner />
        </Center>
      );
    }

    if (itemCount === 0) {
      return renderEmptyCart();
    }

    return (
      <Flex direction="column" width="100%" marginTop={{ base: 0, md: 6 }}>
        {/* WIP SC-204: https://imagequix.atlassian.net/browse/SC-204
              Add logic around locked categories, finish building component below:
          */}
        {/* <UnlockedCategoryMenu /> */}
        <Flex
          alignItems="center"
          direction={{ base: 'column', md: 'row' }}
          marginBottom={{ base: 0, md: 2 }}
          marginTop={{ base: 10, md: 0 }}
        >
          <Heading fontSize="2xl">{reviewCart}</Heading>

          {isMobile && (
            <>
              <Flex alignItems="center" marginTop={2} marginBottom="50px">
                {itemCount > 0 && <Text>{items}</Text>}
                <Divider
                  borderColor="grey.4"
                  borderLeftWidth={1}
                  height={5}
                  marginX={4}
                  orientation="vertical"
                />
                <Text fontSize="md" fontFamily="body">
                  {formatCurrency(summaryFinancials.subtotal, currency)}
                </Text>
              </Flex>

              {!isPreOrder && <SignInBanner />}
              <YearbookAlert />

              <Divider />
            </>
          )}
        </Flex>

        {itemCount === 0 && <Text paddingTop={4}>{NO_ITEMS_IN_CART}</Text>}

        {Object.keys(cartMap)
          .filter(visitKey => !!galleryMap[visitKey])
          .map(visitKey => (
            <Cart key={visitKey} visitKey={visitKey} />
          ))}
        {isMobile && (
          <>
            <Flex ref={checkoutBtnRef}>{renderMobileCheckoutBtn()}</Flex>
            {!elemInView && (
              <Slide direction="bottom" in={!elemInView} style={{ zIndex: NAVIGATION_Z_INDEX }}>
                <StickyMobileSection
                  containerHeight="75px"
                  direction="bottom"
                  paddingBottom={3}
                  show={!elemInView}
                >
                  {renderMobileCheckoutBtn()}
                </StickyMobileSection>
              </Slide>
            )}
            <Divider marginTop={4} marginBottom={2} />
          </>
        )}
        {isMobile && (
          <Box paddingX={4} marginY={8}>
            <OrderSummary financials={summaryFinancials} isCartView />
          </Box>
        )}
      </Flex>
    );
  };

  const renderCartFinancials = () => {
    if (isMobile || itemCount === 0) {
      return null;
    }

    return (
      <Flex direction="column" height="100%">
        <RoundedBorderBox height="100%" paddingTop={6} paddingX={6} width={['250px', '350px']}>
          <OrderSummary financials={summaryFinancials} isCartView />
          <Divider marginTop={2} marginBottom={4} />
          <Button
            disabled={checkingOut}
            fontSize="sm"
            isLoading={checkingOut}
            loadingText={CONTINUE_TO_CHECKOUT}
            onClick={handleClickCheckout}
            width="100%"
          >
            {CONTINUE_TO_CHECKOUT}
          </Button>
        </RoundedBorderBox>
      </Flex>
    );
  };

  const renderEmptyCart = () => {
    return (
      <Flex direction="column" marginTop={6}>
        <VStack minHeight="250px" flexGrow={1}>
          <Heading size="md" textAlign="center" data-test="no-results-found-text">
            {intl.formatMessage({
              id: 'cart.emptyCartHeader',
              defaultMessage: 'Your Cart is Empty',
            })}
          </Heading>
          <Text
            data-test="no-results-found-try-different-search-text"
            fontSize="sm"
            textAlign="center"
          >
            {intl.formatMessage({
              id: 'cart.emptyCart',
              defaultMessage:
                "It's time to start shopping! Shop a category below to start adding items to your cart.",
            })}
          </Text>
          <Box padding="20px">
            <ButtonLink label={SHOP_ALL_CATEGORIES} to={`/${key}/shop/all`} />
          </Box>
          <Box maxWidth={'900px'} paddingBottom="20" width="100%">
            {sortedCategoryIDs.map(categoryID => (
              <Box
                key={categoryID}
                display="inline-block"
                paddingLeft={isMobile ? 0 : '80px'}
                paddingRight={isMobile ? 0 : '40px'}
                textAlign={isMobile ? 'center' : 'left'}
                width={isMobile ? '100%' : '50%'}
              >
                <Link
                  as={RouterLink}
                  margin="auto"
                  to={`/${key}/shop/${categoryID}`}
                  variant="brand"
                >
                  {categories[categoryID].name}
                </Link>
              </Box>
            ))}
          </Box>
        </VStack>
        <Box marginBottom="35px" maxWidth={CONTAINER_MAX_WIDTH} marginX="10px" position="relative">
          {!!packages.length && (
            <ProductCarousel
              heading={intl.formatMessage({
                id: 'noResultsFound.shopPackages',
                defaultMessage: 'Shop Packages',
              })}
              items={packages}
            />
          )}
          {!!productsAndPrints.length && (
            <ProductCarousel
              heading={intl.formatMessage({
                id: 'noResultsFound.shopProductsPrints',
                defaultMessage: 'Shop Products & Prints',
              })}
              items={productsAndPrints}
            />
          )}
        </Box>
      </Flex>
    );
  };

  return (
    <Center>
      <Flex
        direction="column"
        margin="0 auto"
        marginX={{ base: 4, lg: 0 }}
        marginY={6}
        maxWidth={{ base: '100%', lg: CONTAINER_MAX_WIDTH }}
        minWidth={width < 1115 ? '100%' : CONTAINER_MAX_WIDTH}
        paddingX={{ base: 4, lg: 0 }}
      >
        {!isMobile && !isPreOrder && <SignInBanner />}

        <Flex direction="row">
          <Flex direction="column" marginRight={{ base: 0, lg: '55px' }} width="100%">
            {showYearbookAlert && !isMobile && <YearbookAlert />}
            {renderCarts()}
          </Flex>
          {renderCartFinancials()}

          <CartsModal
            checkingOut={checkingOut}
            handleCheckout={handleCheckout}
            possibleCheckouts={possibleCheckouts}
            setShowModal={setShowModal}
            showModal={showModal}
          />
        </Flex>
      </Flex>
    </Center>
  );
};

export default Carts;
