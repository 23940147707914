import {
  Box,
  BoxProps,
  Collapse,
  Flex,
  Heading,
  IconButton,
  Text,
  TypographyProps,
} from '@chakra-ui/react';
import React, { ReactNode, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useIntl } from 'react-intl';

interface Props extends BoxProps {
  children: ReactNode;
  defaultCollapsed?: boolean;
  heading?: string;
  headingColor?: string;
  headingFontSize?: TypographyProps['fontSize'];
  isCollapsible?: boolean;
  hideBorder?: boolean;
}

/**
 * Wraps sections of content that is displayed on the `ProductDetails` view, so that they have consistent
 * styling, and are optionally collapsible.
 */
const DetailsSection = ({
  children,
  defaultCollapsed = false,
  heading,
  headingColor = 'black',
  headingFontSize = 'xl',
  isCollapsible,
  hideBorder,
  ...rest
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(!defaultCollapsed);
  const intl = useIntl();

  const toggleIsExpanded = () => {
    if (!isCollapsible) {
      return;
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      data-test={heading && `product-container-${heading}`}
      borderBottomColor={hideBorder ? 'transparent' : 'grey.2'}
      borderBottomWidth="1px"
      marginBottom={6}
      marginTop="20px"
      onClick={toggleIsExpanded}
      paddingBottom={hideBorder ? 3 : 7}
      {...rest}
    >
      <Flex align="center" justify="space-between" width="100%">
        {heading && (
          <Heading color={headingColor} fontSize={headingFontSize}>
            {heading}
          </Heading>
        )}
        {heading === 'Add Text' && (
          <Text fontSize="12px" color="#737475">
            (On File Data Concealed for Privacy)
          </Text>
        )}
        {isCollapsible && (
          <IconButton
            aria-label={intl.formatMessage({
              id: 'sidebarSection.toggleCollapse',
              defaultMessage: 'Toggle collapse',
            })}
            fontSize="2xl"
            icon={isExpanded ? <FiChevronUp /> : <FiChevronDown />}
            variant="nav"
          />
        )}
      </Flex>
      {isCollapsible ? (
        <Collapse in={isExpanded} unmountOnExit>
          {children}
        </Collapse>
      ) : (
        <Box>{children}</Box>
      )}
    </Box>
  );
};

export default DetailsSection;
