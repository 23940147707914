import { useMediaQuery } from '@chakra-ui/react';
import { ImageNode } from 'iq-product-render';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ShopBackground, ShopImage } from '../../../../../../../shop-api-client';
import { CartImageNodeCrop } from '../../../../../../../shop-api-client/models/Cart';
import Modal from '../../../../../../shared/components/Modal';
import {
  CHOOSE_BACKGROUND_HEADING,
  CHOOSE_BACKGROUND_SUBHEADING,
  CONFIRM_YOUR_CROP,
  CROP,
  NEXT_PREFIX,
  SAVE,
  UPDATE,
} from '../../../constants';
import CropEditor from '../../CropEditor';
import { getCropMobileBuffer } from '../../CropEditor/utils';
import ImageNodeBackgroundDisplay from '../ImageNodeBackgroundDisplay';

interface Props {
  editing: boolean;
  flipSingleImageNode: boolean;
  imageNode?: ImageNode;
  initialBackground: ShopBackground | undefined;
  initialCrop?: CartImageNodeCrop;
  onClose(): void;
  onSave(background?: ShopBackground, crop?: CartImageNodeCrop): void;
  selectedImg: ShopImage;
  showModal: boolean;
  steps: ('background' | 'crop')[];
}

/** Renders a section that allows users to modify an image node */
const ImageNodeModal = ({
  editing,
  flipSingleImageNode,
  imageNode,
  onClose,
  onSave,
  initialBackground,
  selectedImg,
  steps,
  initialCrop,
  showModal,
}: Props) => {
  const [selectedBackground, setSelectedBackground] = useState(initialBackground);
  const [stepIdx, setStepIdx] = useState(0);
  const [errorState, setErrorState] = useState(false);
  const [userCrop, setUserCrop] = useState<CartImageNodeCrop | undefined>(initialCrop);

  const [isMobile] = useMediaQuery('(max-width: 48em)', { ssr: false });
  const intl = useIntl();

  const cropSubheading = intl.formatMessage({
    id: 'imageNodeModal.crop.confirmCropMsg',
    defaultMessage: 'All images are printed exactly as shown.  Make sure you like how it looks.',
  });

  const headingLookup = {
    background: { heading: CHOOSE_BACKGROUND_HEADING, subHeading: CHOOSE_BACKGROUND_SUBHEADING },
    crop: { heading: CONFIRM_YOUR_CROP, subHeading: cropSubheading },
  };
  const step = steps[stepIdx];

  useEffect(() => {
    setSelectedBackground(initialBackground);
  }, [initialBackground]);

  // TODO: potentially turn this into an object later
  // image is unused, but the child expects a function with both these args
  const handleBackgroundSelection = (image: ShopImage, background: ShopBackground) => {
    if (errorState) {
      setErrorState(false);
    }
    setSelectedBackground(background);
  };

  /**
   * This needs to be a useCallback because it's passed to the CropEditor
   * which uses this in a useEffect.
   *
   * If this is not a callback, the useEffect calls his function, which sets state,
   * which recalculate sthis function, which re-triggers the useEffect, ad infinitum.
   */
  const handleCrop = useCallback(
    (userCrop: CartImageNodeCrop) => setUserCrop(userCrop),
    [setUserCrop],
  );

  const handleActionButton = () => {
    // Background selection is required if included as a state.
    // If not chosen, set error state:
    if (step === 'background' && !selectedBackground) {
      setErrorState(true);
    } else if (steps[stepIdx + 1]) {
      setStepIdx(stepIdx + 1);
    } else {
      onSave(selectedBackground, userCrop);
    }
  };

  const getActionLabel = () => {
    if (editing) {
      return UPDATE;
    }

    if (steps[stepIdx + 1] === 'crop') {
      return `${NEXT_PREFIX} ${CROP}`;
    }
    if (!steps[stepIdx + 1]) {
      return SAVE;
    }
  };

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      actionLabel={getActionLabel()}
      contentStyles={{
        display: 'flex',
        margin: undefined,
        maxWidth: undefined,
        maxHeight: undefined,
        minHeight: undefined,
        height: isMobile ? undefined : window.innerHeight,
      }}
      bodyStyles={{ display: 'flex', flex: 1 }}
      footerFlexProps={{ direction: 'row-reverse' }}
      heading={headingLookup[step].heading}
      isOpen
      onClose={onClose}
      onConfirm={handleActionButton}
      size={isMobile ? 'full' : '3xl'}
      subHeading={headingLookup[step].subHeading}
      unwrapChildren
      variant={isMobile ? 'full' : undefined} // workaround to allow 'full' to work in iOS (see theme extension modal.ts)
    >
      {step === 'background' && (
        <ImageNodeBackgroundDisplay
          error={errorState}
          image={selectedImg}
          selectBackground={handleBackgroundSelection}
          selectedBackgrounds={selectedBackground?.id ? [selectedBackground.id] : []}
        />
      )}
      {step === 'crop' && imageNode && (
        <CropEditor
          flipSingleImageNode={flipSingleImageNode}
          handleCrop={handleCrop}
          imageNode={imageNode}
          initialCrop={userCrop}
          inlineControls
          shopBackground={selectedBackground}
          shopImage={selectedImg}
          singleNodeMaxHeight={isMobile ? undefined : window.innerHeight - 100}
          singleNodeMaxWidth={
            isMobile
              ? window.innerWidth -
                getCropMobileBuffer(
                  { 600: 140, 700: 100, base: 50 },
                  imageNode.width / imageNode.height > 1 ? { base: 50 } : undefined,
                )
              : undefined
          }
          forceHeight={isMobile}
          forceWidth={isMobile}
        />
      )}
    </Modal>
  );
};

export default ImageNodeModal;
