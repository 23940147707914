import {
  AspectRatio,
  Box,
  BoxProps,
  Button,
  Flex,
  Spinner,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShopPackage, ShopProduct } from '../../../../shop-api-client';
import { CartPackage, CartProduct } from '../../../../shop-api-client/models/Cart';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import { validateGalleryImageSupport } from '../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import { selectCanAutoAddMap } from '../../../redux/selectors/interactions.selectors';
import { selectPriceSheet } from '../../../redux/selectors/priceSheet.selectors';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { addSelectAndBuyItemToCart } from '../../../redux/thunks/cart.thunks';
import { Params } from '../../../shared/types/router';
import { formatCurrency } from '../../../shared/utils';
import useConfigurationRouter from '../Configuration/useConfigurationRouter';
import ProductImage from '../ProductImage';
import ProductTags from '../ProductTags';
import QuickViewModal from '../QuickViewModal';
import { getCustomizeLabel } from '../utils';

interface Props extends BoxProps {
  cardMargin?: string | number;
  maxCardWidth: string;
  mobileCardWidth?: string;
  onAddToCart?(item: CartProduct | CartPackage): void;
  onCardClick(productID: number): void;
  product: ShopProduct | ShopPackage;
  showDetails?: boolean;
  showQuickView?: boolean;
}

const ProductCard = ({
  cardMargin,
  maxCardWidth,
  mobileCardWidth,
  onAddToCart = () => {},
  onCardClick,
  product,
  showDetails,
  showQuickView,
  ...rest
}: Props) => {
  const gallery = useSelector(selectGallery);
  const { offersStatusMap } = useSelector(selectPriceSheet);
  const canAutoAddMap = useSelector(selectCanAutoAddMap);
  const { accountID, currency } = useSelector(selectAccount);
  const validGalleryProduct = useAppSelector(state => validateGalleryImageSupport(state, product));

  const [addingToCart, setAddingToCart] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { routeToConfigurationPath } = useConfigurationRouter();
  const { key } = useParams<Params>();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const isLocked = offersStatusMap[product.categoryID]?.isLocked;
  const width = isMobile && mobileCardWidth ? mobileCardWidth : maxCardWidth;

  const buyModeButtonLabel = getCustomizeLabel(
    !validGalleryProduct,
    canAutoAddMap[product.id],
    isMobile,
  );

  const handleSelectAndBuy = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!canAutoAddMap[product.id]) {
      routeToConfigurationPath(key, product, gallery, true);
      return;
    }
    setAddingToCart(true);

    const { valid, payload } = await dispatch(addSelectAndBuyItemToCart(product));

    if (!valid || !payload) {
      setAddingToCart(false);
      // TODO: Criteria pending. Display "Choose Options panel/view"
      // SC-203: https://imagequix.atlassian.net/browse/SC-203
      throw new Error('WIP: criteria pending for Choose Options');
    }

    onAddToCart(payload);
    setAddingToCart(false);
  };

  const toggleQuickView = (e?: React.MouseEvent) => {
    // If mouse event (opposed to using ESC to exit modal), stop event from bubbling up:
    if (e) {
      e.stopPropagation();
    }
    setIsOpen(!isOpen);

    // Google Analytics select_item event.
    window.gtag('event', 'select_item', {
      item_list_id: product.categoryID,
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          item_category: product.type,
          affiliation: accountID,
          price: product.price,
        },
      ],
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      margin={cardMargin}
      sx={{ scrollSnapAlign: 'start', scrollSnapStop: 'normal' }}
      width={width}
      data-test={`product-cards-${product.name}`}
      {...rest}
    >
      <ProductTags product={product} />
      {isOpen && <QuickViewModal isOpen={isOpen} onClose={toggleQuickView} product={product} />}
      <AspectRatio
        alignItems="center"
        backgroundColor="grey.1"
        justifyContent="center"
        ratio={1}
        width={isMobile && showDetails ? '100%' : width}
        _hover={{
          cursor: 'pointer',
        }}
      >
        <Box height="100%" onClick={() => onCardClick(product.id)} width="100%">
          <ProductImage
            height="100%"
            image={product.images?.length ? product.images[0] : product.image || ''}
            isLocked={isLocked}
            key={`${mobileCardWidth}-${product.id}`}
            product={product}
            isSquare
          />
        </Box>
      </AspectRatio>
      <Flex marginX="3px" alignItems="stretch" direction="column">
        {showQuickView && (
          <Button
            color="deepCyan"
            onClick={toggleQuickView}
            variant="borderSmall"
            data-test={`product-card-quickview-${product.name}`}
          >
            {intl.formatMessage({
              id: 'productCard.quickView',
              defaultMessage: 'Quick View',
            })}
          </Button>
        )}
        <Text
          fontFamily="ITC Avant Garde Gothic Demi"
          lineHeight="initial"
          marginTop="12px"
          onClick={() => onCardClick(product.id)}
          data-test={`product-card-name-${product.name}`}
          _hover={{
            cursor: 'pointer',
          }}
        >
          {product.name}
        </Text>
        <Text
          fontSize="md"
          color="brand"
          fontFamily="ITC Avant Garde Gothic Demi"
          marginY="5px"
          data-test={`product-price-${product.name}`}
        >
          {formatCurrency(product.price, currency)}
        </Text>
        {showDetails && (
          <Text
            fontSize="14px"
            marginBottom="20px"
            marginTop="8px"
            noOfLines={5}
            whiteSpace="normal"
            data-test={`product-card-description-${product.id}`}
          >
            {product.description}
          </Text>
        )}
      </Flex>
      <Button
        data-test={`product-card-add-to-cart-button-${product.name}`}
        disabled={isLocked || addingToCart || !validGalleryProduct}
        marginBottom={3}
        marginTop="auto"
        onClick={handleSelectAndBuy}
        fontSize="16px"
        height="45px"
        width="100%"
      >
        {addingToCart ? <Spinner size="sm" /> : buyModeButtonLabel}
      </Button>
    </Box>
  );
};

export default ProductCard;
