import { Alert, Box, Button, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ShopProductCollection } from '../../../../../../../shop-api-client';
import {
  CartCollection,
  CartCollectionReq,
  CartDownload,
  CartDownloadReq,
} from '../../../../../../../shop-api-client/models/Cart';
import { selectBackgroundsMap } from '../../../../../../redux/selectors/background.selectors';
import {
  selectConfiguration,
  selectEditItemAvailableImages,
} from '../../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../../redux/selectors/gallery.selectors';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/store';
import ThumbnailWithBackground from '../../../../../../shared/components/ThumbnailWithBackground';
import { Params } from '../../../../../../shared/types/router';
import { shapeCollectionImage } from '../../../utils';
import { updateEditProductCollectionImgs } from '../../../../../../redux/thunks/configuration.thunks';

const BOX_SIZE = 80;

interface Props {
  editProduct: CartCollection | CartDownload | CartCollectionReq | CartDownloadReq;
  shopProduct: ShopProductCollection;
}

const PackageCollectionPreview = ({ editProduct, shopProduct }: Props) => {
  const { key } = useParams<Params>();
  const backgroundsMap = useAppSelector(selectBackgroundsMap);
  const { editPackageStep, invalidSteps } = useAppSelector(selectConfiguration);
  const { images } = useAppSelector(state => selectGallery(state, key));
  const availableImages = useAppSelector(selectEditItemAvailableImages);
  const dispatch = useAppDispatch();

  const [error, setError] = useState(false);

  const intl = useIntl();
  const { imageRequirementType, includeAll, minImages, maxImages } = shopProduct;
  const collectionImages = editProduct.collectionImages.filter(ci => !!ci.internalName);

  useEffect(() => {
    if (editPackageStep) {
      setError(invalidSteps[editPackageStep]);
    }
  }, [editPackageStep, invalidSteps]);

  const allImagesAreIncluded = intl.formatMessage({
    id: 'collectionSelection.allImagesAreIncluded',
    defaultMessage: 'All your photos are included.',
  });

  const selectMinPhotos = intl.formatMessage(
    {
      id: 'collectionSelection.selectMinPhotos',
      defaultMessage: 'You must select at least {minImages} {photo}.',
    },
    {
      minImages,
      photo: minImages === 1 ? 'photo' : 'photos',
    },
  );

  const selectMaxPhotos = intl.formatMessage(
    {
      id: 'collectionSelection.selectMaxPhotos',
      defaultMessage: 'You can select up to {maxImages} {photo}.',
    },
    {
      maxImages,
      photo: maxImages === 1 ? 'photo' : 'photos',
    },
  );

  const imagesSelected = intl.formatMessage({
    id: 'collectionSelection.imagesSelected',
    defaultMessage: 'Images Selected:',
  });

  const imagesSelectedOfMax = intl.formatMessage(
    {
      id: 'collectionSelection.imagesSelectedOfMax',
      defaultMessage: '{selected} of {max}',
    },
    {
      selected: collectionImages.filter(ci => !!ci.internalName).length,
      max: maxImages,
    },
  );

  const imgRequirementTypeMsg = intl.formatMessage(
    {
      id: 'collectionSelection.imgRequirementTypeMsg',
      defaultMessage: 'No {imgRequirement} photos allowed.',
    },
    {
      imgRequirement: imageRequirementType === 'nonGroup' ? 'group' : 'subject',
    },
  );

  const renderSelectAll = () => {
    const allImages = availableImages.products[editProduct.id!];
    // Select all is disabled if there are green screen images,
    // if there are more images than the max allowed,
    // or if the user has already selected the max number of images
    if (
      allImages.some(image => image.isGreenScreen) ||
      allImages.length > maxImages ||
      collectionImages.length === maxImages
    ) {
      return null;
    }

    return (
      <Flex marginBottom={3}>
        <Button onClick={selectAll} variant="link" margin={0}>
          Select all
        </Button>
      </Flex>
    );
  };

  const selectAll = () => {
    const allImages = availableImages.products[editProduct.id!];
    dispatch(
      updateEditProductCollectionImgs(
        editProduct.id!,
        allImages.map(image => shapeCollectionImage(image)),
      ),
    );
  };

  const renderClear = () => {
    // Clear is disabled if there are less than 2 images selected
    if (collectionImages.length < 2) {
      return null;
    }

    return (
      <Flex alignSelf="center" marginLeft="auto" marginRight={4}>
        <Button
          onClick={handleClear}
          variant="link"
          color="red.600"
          textDecoration="none"
          margin={0}
        >
          Clear
        </Button>
      </Flex>
    );
  };

  const handleClear = () => {
    dispatch(updateEditProductCollectionImgs(editProduct.id!, []));
  };

  const renderExplanation = () => {
    return (
      <Alert variant="info" borderRadius="md" marginY={4}>
        <Box lineHeight="short">
          {includeAll ? (
            <Text variant="inline" marginRight={1}>
              {allImagesAreIncluded}
            </Text>
          ) : (
            <>
              <Text variant="inline" marginRight={1}>
                {selectMinPhotos}
              </Text>
              <Text variant="inline" fontFamily="ITC Avant Garde Gothic Book" marginRight={1}>
                {selectMaxPhotos}
              </Text>
              {imageRequirementType !== 'any' && (
                <Text variant="inline" fontFamily="ITC Avant Garde Gothic Book">
                  {imgRequirementTypeMsg}
                </Text>
              )}
            </>
          )}
        </Box>
      </Alert>
    );
  };

  const renderSelections = () => {
    if (includeAll) {
      // Selections aren't rendered when all images are included
      return null;
    }

    return (
      <>
        <Flex alignItems="baseline">
          <Text fontFamily="ITC Avant Garde Gothic Demi" marginRight={1} marginBottom={3}>
            {imagesSelected}
          </Text>
          <Text fontFamily="ITC Avant Garde Gothic Book">{imagesSelectedOfMax}</Text>
          {renderClear()}
        </Flex>
        {renderSelectAll()}
        <Flex direction="row" wrap="wrap">
          {[...Array(maxImages)].map((imgSlot, index) => {
            const img = images[collectionImages[index]?.internalName!];
            const bg = backgroundsMap[collectionImages[index]?.backgroundID || ''];

            if (img) {
              return (
                <Box
                  key={index}
                  height={`${BOX_SIZE}px`}
                  width={`${BOX_SIZE}px`}
                  marginRight={2}
                  marginBottom={2}
                >
                  <ThumbnailWithBackground
                    src={img.sources.thumb}
                    background={bg?.sources?.thumb}
                    objectFit="cover"
                    height={`${BOX_SIZE}px`}
                    width={`${BOX_SIZE}px`}
                    borderRadius="md"
                  />
                </Box>
              );
            }

            return (
              <Box
                key={index}
                borderStyle="dashed"
                borderColor={index < minImages ? (error ? 'error' : 'grey.4') : 'grey.2'}
                borderRadius="md"
                borderWidth="2px"
                h={`${BOX_SIZE}px`}
                marginRight={2}
                marginBottom={2}
                w={`${BOX_SIZE}px`}
              />
            );
          })}
        </Flex>
      </>
    );
  };

  return (
    <Flex direction="column" width="100%">
      {renderExplanation()}
      {renderSelections()}
    </Flex>
  );
};

export default PackageCollectionPreview;
