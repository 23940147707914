import { Center, Flex, Spinner } from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance } from '@stripe/stripe-js';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { StepKey } from '../../../shop-api-client';
import {
  selectCheckoutActiveStep,
  selectCheckoutData,
  selectCheckoutSlice,
  selectCheckoutSteps,
} from '../../redux/selectors/checkout.selectors';
import {
  setCheckoutActiveStep,
  setCheckoutStepComplete,
  setCheckoutStepIncomplete,
} from '../../redux/slices/checkout.slice';
import { useAppDispatch } from '../../redux/store';
import { getCheckout, getCheckoutFinancials } from '../../redux/thunks/checkout.thunks';
import { getStripe } from '../../shared/constants/stripe';
import { Params } from '../../shared/types/router';
import CheckoutAddressForm from './CheckoutAddressForm';
import CheckoutBgOptions from './CheckoutBgOptions';
import CheckoutCustomForm from './CheckoutCustomForm';
import CheckoutPayment from './CheckoutPayment';
import CheckoutShippingOptions from './CheckoutShippingOptions';
import CheckoutStep from './CheckoutStep';
import CheckoutSummary from './CheckoutSummary';
import OrderOptions from './OrderOptions';
import RequiredImageOptions from './RequiredImageOptions';

const Checkout = () => {
  // Selectors
  const { financials, checkoutStepCompletion, paymentIntent } = useSelector(selectCheckoutSlice);
  const activeStep = useSelector(selectCheckoutActiveStep);
  const checkoutData = useSelector(selectCheckoutData);
  const steps = useSelector(selectCheckoutSteps);

  // State
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validateCallback, setValidateCallback] = useState<{
    validate: () => Promise<boolean>;
  } | null>();

  // Hooks
  const { checkoutID, key } = useParams<Params>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const stepsRef = useRef<Record<string, HTMLDivElement>>({});

  // Shows/hides the navigation bar
  // Loads the checkout (only once when mounting)
  useEffect(() => {
    const init = async () => {
      if (checkoutID) {
        const { valid } = await dispatch(getCheckout(checkoutID));
        if (!valid) {
          // Something went wrong with this checkout, send them back to cart:
          history.push(`/${key}/carts`);
        }
      }
    };

    init();
  }, [checkoutID, dispatch, history, key]);

  // Reset checkout error state whenever data changes or active step changes
  useEffect(() => {
    setError(false);
  }, [activeStep, checkoutData]);

  // Make sure to scroll the active step into view:
  useEffect(() => {
    if (!activeStep?.stepKey) {
      return;
    }

    // Scroll to next div
    if (stepsRef.current[activeStep.stepKey]) {
      stepsRef.current[activeStep.stepKey].scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeStep?.stepKey]);

  const appearance: Appearance = {
    labels: 'above',
    variables: { borderRadius: '8px', colorDanger: '#A80000' },
    rules: {
      '.Input': {
        borderColor: '#bebfc0',
        borderWidth: '2px',
        boxShadow: 'none',
        fontWeight: 'bold',
      },
      '.Label--invalid': {
        color: '#A80000',
        fontSize: '16px',
      },
      '.Input--invalid': {
        borderWidth: '2px',
        boxShadow: 'none',
        fontWeight: 'bold',
      },
      '.Error': {
        fontSize: '12px',
        marginLeft: '5px',
        marginTop: '8px',
      },
    },
  };

  // Edit an already completed step
  const handleEdit = (step: StepKey) => {
    dispatch(setCheckoutStepIncomplete(step));
    dispatch(setCheckoutActiveStep(step));
  };

  // Complete a step, validate, and move onto next one
  const handleNext = async (stepIndex: number) => {
    if (!checkoutID || !steps) {
      return;
    }

    const validate = await validateCallback?.validate();
    setError(!!validate);

    if (!validate) {
      return;
    }

    // Mark this step as complete, null out the active step again
    // to allow the selector to pickup the next incomplete step:
    dispatch(setCheckoutActiveStep(null));
    dispatch(setCheckoutStepComplete(steps[stepIndex].stepKey));
    await dispatch(getCheckoutFinancials(checkoutID));

    // Google analytics add_shipping_info and add_payment_info events
    if (steps[stepIndex].stepKey === 'shippingAddress') {
      window.gtag('event', 'add_shipping_info', {
        shipping_tier: checkoutData.shippingType,
      });
    }
    if (steps[stepIndex].stepKey === 'paymentOptions') {
      window.gtag('event', 'add_payment_info', {
        payment_type: 'Stripe',
      });
    }
  };

  const scrollToPaymentOptions = () =>
    stepsRef.current.paymentOptions?.scrollIntoView({ behavior: 'smooth' });

  // Check which component to render based on the steps initialized earlier
  const renderCheckoutStep = (index: number) => {
    const step = steps[index];
    const isActive = activeStep?.stepKey === step.stepKey;

    const props = {
      isActive,
      isComplete: checkoutStepCompletion[step.stepKey],
      setLoading,
      // Only pass setter for the validate callback on the active step:
      setValidateCallback: isActive ? setValidateCallback : () => {},
      stepKey: step.stepKey,
    };

    switch (step.stepKey) {
      case 'customDataSpec':
        return <CheckoutCustomForm {...props} />;
      case 'shippingType':
        return <CheckoutShippingOptions {...props} />;
      case 'backgroundOptions':
        return <CheckoutBgOptions {...props} />;
      case 'requiredImageOptions':
        return <RequiredImageOptions {...props} />;
      case 'shippingAddress':
        return <CheckoutAddressForm {...props} hasTaxState stepKey="shippingAddress" />;
      case 'orderOptions':
        return <OrderOptions {...props} />;
      case 'billingAddress':
        return <CheckoutAddressForm {...props} showEmail showPhone stepKey="billingAddress" />;
      case 'paymentOptions':
        return (
          <CheckoutPayment
            hideBilling={steps.some(step => step.stepKey === 'billingAddress')}
            scrollToPaymentOptions={scrollToPaymentOptions}
            showUseShipping={steps.some(step => step.stepKey === 'shippingAddress')}
            showStripe={!!paymentIntent && financials.total !== 0}
            validateCallback={validateCallback}
            {...props}
          />
        );
    }
  };

  const renderCheckoutElement = () => {
    if (!activeStep || !steps.length) {
      return <Flex width="100%" />;
    }

    return (
      <Elements
        key={paymentIntent}
        stripe={getStripe()}
        options={{ clientSecret: paymentIntent, appearance, loader: 'always' }}
      >
        <Flex flexFlow="column" width="100%">
          {steps.map((step, index) => (
            <Flex ref={ref => (stepsRef.current[step.stepKey] = ref!)} key={index}>
              <CheckoutStep
                contentWidth={step.stepKey === 'backgroundOptions' ? '695px' : undefined}
                error={error}
                handleEdit={handleEdit}
                handleNext={handleNext}
                isActive={activeStep?.stepKey === step.stepKey}
                isComplete={!!checkoutStepCompletion[step.stepKey]}
                loading={loading}
                stepIndex={index}
                stepKey={step.stepKey}
                stepTitle={step.stepTitle}
              >
                {renderCheckoutStep(index)}
              </CheckoutStep>
            </Flex>
          ))}
        </Flex>
      </Elements>
    );
  };

  if (!steps.length) {
    return (
      <Center width="100%" height="50vh">
        <Spinner />
      </Center>
    );
  }

  return (
    <Flex
      flexFlow={{ base: 'column', lg: 'row' }}
      height="100%"
      justifyContent="center"
      margin="0 auto"
      marginBottom={8}
      marginTop={{ base: 0, lg: 8 }}
      maxWidth="1150px"
      paddingX={4}
      width="100%"
    >
      {renderCheckoutElement()}
      <CheckoutSummary financials={financials} />
    </Flex>
  );
};

export default Checkout;
