import { intl } from '../../../shared/constants';

// -------------------------------------------------------------------------
// General constants
// -------------------------------------------------------------------------

export const DEFAULT_NODE_CROP = {
  cropX: 50,
  cropY: 50,
  cropW: 100,
  cropH: 100,
  orientation: 0,
};
export const MAX_PREVIEW_SIZE = 600;
export const MAX_PREVIEW_HEIGHT = 800;
// PNG bg string for gs images, since we don't render the bg
export const PNG_BG_SVG = `rgb(250, 250, 250) url('data:image/svg+xml,\
<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill-opacity=".05" >\
         <rect x="4" width="4" height="4" />\
         <rect y="4" width="4" height="4" />\
         </svg>')`;
export const THUMB_GRID_MAX_WIDTH = '130px';
export const THUMB_GRID_MAX_HEIGHT = '150px';
export const TOOLBAR_HEIGHT = 100;

// -------------------------------------------------------------------------
// Internationalized text - organized alphabetically
// -------------------------------------------------------------------------

export const ADDITIONAL_CART_ITEMS = intl.formatMessage({
  id: 'configuration.constants.additional',
  defaultMessage: 'Additional Cart Items',
});

export const ADD_ONS = intl.formatMessage({
  id: 'configuration.constants.AddOns',
  defaultMessage: 'Add-Ons',
});

export const ADD_ONS_WITH_COLON = intl.formatMessage({
  id: 'configuration.constants.AddOnsColon',
  defaultMessage: 'Add-Ons: ',
});

export const ADD_PHOTO_EFFECT = intl.formatMessage({
  id: 'configuration.constants.addPhotoEffect',
  defaultMessage: 'Add Photo Effect',
});

export const ADD_TEXT = intl.formatMessage({
  id: 'configuration.constants.addText',
  defaultMessage: 'Add Text',
});

export const ADDITIONAL_POSES = intl.formatMessage({
  id: 'configuration.constants.additionalPoses',
  defaultMessage: 'Additional Poses: ',
});

export const ALL_ITEMS = intl.formatMessage({
  id: 'configuration.constants.allItems',
  defaultMessage: 'All Items',
});

export const ALL_PHOTOS = intl.formatMessage({
  id: 'configuration.constants.allPhotos',
  defaultMessage: 'All Photos',
});

export const ARIA_RESET = intl.formatMessage({
  id: 'configuration.constants.ariaReset',
  defaultMessage: 'Reset',
});

export const ARIA_ROTATE_LEFT = intl.formatMessage({
  id: 'configuration.constants.ariaRotateLeft',
  defaultMessage: 'Rotate left',
});

export const ARIA_ROTATE_RIGHT = intl.formatMessage({
  id: 'configuration.constants.ariaRotateRight',
  defaultMessage: 'Rotate right',
});

export const ARIA_ZOOM_IN = intl.formatMessage({
  id: 'configuration.constants.ariaRotateZoomIn',
  defaultMessage: 'Zoom in',
});

export const ARIA_ZOOM_OUT = intl.formatMessage({
  id: 'configuration.constants.ariaZoomOut',
  defaultMessage: 'Zoom out',
});

export const AVAILABLE_PHOTOS = intl.formatMessage({
  id: 'configuration.constants.availablePhotos',
  defaultMessage: 'Available Photos',
});

export const BACKGROUND_WITH_COLON = intl.formatMessage({
  id: 'configuration.constants.Background',
  defaultMessage: 'Background: ',
});

export const BACKGROUNDS = intl.formatMessage({
  id: 'configuration.constants.backgrounds',
  defaultMessage: 'Backgrounds: ',
});

export const BLACK_AND_WHITE = intl.formatMessage({
  id: 'configuration.constants.blackAndWhite',
  defaultMessage: 'Black & White',
});

export const BUILD_YOUR_PACKAGE = intl.formatMessage({
  id: 'configuration.constants.buildYourPackage',
  defaultMessage: 'Build Your Package',
});

export const CHANGE_BACKGROUND = intl.formatMessage({
  id: 'configuration.constants.changeBackground',
  defaultMessage: 'Change Background',
});

export const CHANGE_PHOTO = intl.formatMessage({
  id: 'configuration.constants.changePhoto',
  defaultMessage: 'Change Photo',
});

export const CHOOSE_ADD_ONS = intl.formatMessage({
  id: 'configuration.constants.chooseAddOns',
  defaultMessage: 'Choose Add-ons',
});

export const CHOOSE_BACKGROUND_HEADING = intl.formatMessage({
  id: 'configuration.constants.chooseBackgroundHeading',
  defaultMessage: 'Select your background',
});

export const CHOOSE_BACKGROUND_SUBHEADING = intl.formatMessage({
  id: 'configuration.constants.chooseBackgroundSubheading',
  defaultMessage:
    'Choose a background below.  Hide poses by clicking the "Hide Pose" button.  Scroll to view more.',
});

export const CHOOSE_PHOTO_HEADING = intl.formatMessage({
  id: 'configuration.constants.choosePhotoHeading',
  defaultMessage: 'Select your photo',
});

export const CONFIRM_CROP = intl.formatMessage({
  id: 'configuration.constants.confirmYourCrop',
  defaultMessage: 'Confirm your crop',
});

export const CONFIRM_YOUR_CROP = intl.formatMessage({
  id: 'configuration.constants.confirmYourCrop',
  defaultMessage: 'Confirm your crop',
});

export const CROP = intl.formatMessage({
  id: 'configuration.constants.crop',
  defaultMessage: 'Crop',
});

export const CROP_TOOL_ROTATE = intl.formatMessage({
  id: 'configuration.constants.cropToolRotate',
  defaultMessage: 'Rotate',
});

export const CROP_TOOL_ZOOM = intl.formatMessage({
  id: 'configuration.constants.cropToolZoom',
  defaultMessage: 'Zoom',
});

export const DEFAULT_IMG_MSG = intl.formatMessage({
  id: 'configuration.constants.defaultImgMsg',
  defaultMessage: 'Only specific photos are allowed to be used on this item.',
});

export const EDIT = intl.formatMessage({
  id: 'configuration.constants.edit',
  defaultMessage: 'Edit',
});

export const EDIT_CROP = intl.formatMessage({
  id: 'configuration.constants.editCrop',
  defaultMessage: 'Edit Crop',
});

export const EDIT_YOUR_PHOTO = intl.formatMessage({
  id: 'configuration.constants.editYourPhoto',
  defaultMessage: 'Edit your photo',
});

export const FAVORITES = intl.formatMessage({
  id: 'configuration.constants.favorites',
  defaultMessage: 'Favorites',
});

export const FONTS = intl.formatMessage({
  id: 'configuration.constants.fonts',
  defaultMessage: 'Some items have special fonts that will render when the item is processed.',
});

export const GROUP_PHOTOS = intl.formatMessage({
  id: 'configuration.constants.groupPhotos',
  defaultMessage: 'Group Photos',
});

export const IMAGE_ADD_ONS = intl.formatMessage({
  id: 'configuration.constants.imageAddOns',
  defaultMessage: 'Image Add-Ons',
});

export const IMAGE_ADD_ONS_W_COLON = intl.formatMessage({
  id: 'configuration.constants.imageAddOnsColon',
  defaultMessage: 'Image Add-Ons: ',
});

export const IMAGE_NODE_ALL_GROUPS = intl.formatMessage({
  id: 'configuration.constants.imageNodeAllGroups',
  defaultMessage: 'All',
});

export const IMAGE_NODE_ALL_PHOTOS = intl.formatMessage({
  id: 'configuration.constants.imageNodeAllPhotos',
  defaultMessage: 'All Photos',
});

export const IMAGE_NODE_EXPAND = intl.formatMessage({
  id: 'configuration.constants.imageNodeExpand',
  defaultMessage: 'Expand',
});

export const INVALID_BUY_MODE_IMAGE_PROMPT = intl.formatMessage({
  id: 'configuration.constants.invalidBuyModeImagePrompt',
  defaultMessage: 'Photo not supported in this package.',
});

export const MULTI_NODE_CROP_CANCEL = intl.formatMessage({
  id: 'configuration.constants.multiNodeCropCancel',
  defaultMessage: 'Cancel',
});

export const MULTI_NODE_CROP_CONFIRM = intl.formatMessage({
  id: 'configuration.constants.multiNodeCropConfirm',
  defaultMessage: 'Confirm',
});

export const MULTI_NODE_CROP_EDIT = intl.formatMessage({
  id: 'configuration.constants.multiNodeCropEdit',
  defaultMessage: 'Edit Crop',
});

export const MULTI_NODE_CROP_PREVIEW = intl.formatMessage({
  id: 'configuration.constants.multiNodeCropPreview',
  defaultMessage: 'Preview',
});

export const MULTI_NODE_CROP_SAVE = intl.formatMessage({
  id: 'configuration.constants.multiNodeCropSave',
  defaultMessage: 'Save',
});

export const MULTI_NODE_IMAGE_WARNING = intl.formatMessage({
  id: 'configuration.constants.multiNodeImageWarning',
  defaultMessage: 'Choose a photo before continuing',
});

export const NEXT_ADD_ONS = intl.formatMessage({
  id: 'configuration.constants.nextAddOns',
  defaultMessage: 'Next: Add-Ons',
});

export const NEXT_BUILD_PACKAGE = intl.formatMessage({
  id: 'configuration.constants.nextBuildPackage',
  defaultMessage: 'Next: Build Package',
});

export const NEXT_CUSTOMIZE = intl.formatMessage({
  id: 'configuration.constants.nextCustomize',
  defaultMessage: 'Next: Customize',
});

export const NEXT_CUSTOMIZE_ITEMS = intl.formatMessage({
  id: 'configuration.constants.nextCustomizeItems',
  defaultMessage: 'Next: Customize Items',
});

export const NEXT_ITEM = intl.formatMessage({
  id: 'configuration.constants.nextItem',
  defaultMessage: 'Next item',
});

export const NEXT_PREFIX = intl.formatMessage({
  id: 'configuration.constants.nextPrefix',
  defaultMessage: 'Next:',
});

export const NEXT_SUMMARY = intl.formatMessage({
  id: 'configuration.constants.nextSummary',
  defaultMessage: 'Next: Summary',
});

export const NONE_SELECTED = intl.formatMessage({
  id: 'configuration.constants.noneSelected',
  defaultMessage: 'None Selected',
});

export const ON = intl.formatMessage({ id: 'configuration.constants.on', defaultMessage: 'on' });

export const OTHER_PHOTOS = intl.formatMessage({
  id: 'configuration.constants.otherPhotos',
  defaultMessage: 'Other Photos',
});

export const POSE_LIMIT_HEADING = intl.formatMessage({
  id: 'configuration.constants.poseLimitHeading',
  defaultMessage: `Pose Limit Reached`,
});

export const POSE_LIMIT_REACHED = intl.formatMessage({
  id: 'configuration.constants.poseLimitReached',
  defaultMessage: `You've reached the limit of poses allowed for this package.`,
});

export const POSE_LIMIT_REPLACE = intl.formatMessage({
  id: 'configuration.constants.poseLimitReplace',
  defaultMessage: `Choose an image below you'd like to replace.`,
});

export const PHOTO = intl.formatMessage({
  id: 'configuration.constants.photo',
  defaultMessage: 'Photo:',
});

export const PHOTO_ADD_ONS = intl.formatMessage({
  id: 'configuration.constants.photoAddOns',
  defaultMessage: 'Photo Add-Ons',
});

export const PHOTO_NO_COLON = intl.formatMessage({
  id: 'option.photo',
  defaultMessage: 'Photo',
});

export const PHOTO_NUMBER = intl.formatMessage({
  id: 'configuration.constants.photoNumber',
  defaultMessage: 'Photo #',
});

export const PREVIEW_ONLY = intl.formatMessage({
  id: 'configuration.constants.previewOnly',
  defaultMessage: 'Preview only',
});

export const PRODUCT_ADD_ONS = intl.formatMessage({
  id: 'configuration.constants.productAddOns',
  defaultMessage: 'Item Add-Ons: ',
});

export const RECENTLY_USED = intl.formatMessage({
  id: 'configuration.constants.recentlyUsed',
  defaultMessage: 'Recently Used',
});

export const REQUIRED_FIELDS = intl.formatMessage({
  id: 'configuration.constants.requiredFields',
  defaultMessage: 'Please fill out all required fields',
});

export const RESET_CROP = intl.formatMessage({
  id: 'configuration.constants.resetCrop',
  defaultMessage: 'Reset',
});

export const SAVE_AND_CONTINUE = intl.formatMessage({
  id: 'configuration.constants.saveAndContinue',
  defaultMessage: 'Save & Continue',
});

export const SELECT_A_DIFFERENT_PHOTO = intl.formatMessage({
  id: 'configuration.constants.selectADifferentPhoto',
  defaultMessage: 'Please select a different photo.',
});

export const SELECT_PHOTO_SLOT = intl.formatMessage({
  id: 'configuration.constants.selectPhotoSlot',
  defaultMessage: 'Select a photo slot above to begin',
});

export const SELECT_PHOTO = intl.formatMessage({
  id: 'configuration.constants.selectPhoto',
  defaultMessage: 'Select Photo',
});

export const SELECT_YOUR_PHOTO = intl.formatMessage({
  id: 'configuration.constants.selectYourPhoto',
  defaultMessage: 'Select Your Photo',
});

export const SELECT_YOUR_PHOTOS = intl.formatMessage({
  id: 'configuration.constants.selectYourPhotos',
  defaultMessage: 'Select Your Photos',
});

export const SEPIA = intl.formatMessage({
  id: 'configuration.constants.sepia',
  defaultMessage: 'Sepia',
});

export const START_CUSTOMIZING = intl.formatMessage({
  id: 'configuration.constants.startCustomizing',
  defaultMessage: 'Start Customizing',
});

export const SUMMARY = intl.formatMessage({
  id: 'configuration.constants.summary',
  defaultMessage: 'Summary',
});

export const SUMMARY_WITH_COLON = intl.formatMessage({
  id: 'configuration.constants.summaryWithColon',
  defaultMessage: 'Summary:',
});

export const TEXT = intl.formatMessage({
  id: 'configuration.constants.text',
  defaultMessage: 'Text',
});

export const TOTAL = intl.formatMessage({
  id: 'configuration.constants.total',
  defaultMessage: 'Total',
});

export const TOTAL_WITH_COLON = intl.formatMessage({
  id: 'configuration.constants.totalWithColon',
  defaultMessage: 'Total: ',
});

export const UNIT = intl.formatMessage({
  id: 'configuration.constants.Unit',
  defaultMessage: 'Unit',
});

export const UNITS = intl.formatMessage({
  id: 'configuration.constants.Units',
  defaultMessage: 'Units',
});

export const unit = intl.formatMessage({
  id: 'configuration.constants.unit',
  defaultMessage: 'unit',
});

export const units = intl.formatMessage({
  id: 'configuration.constants.units',
  defaultMessage: 'units',
});

export const UPDATE = intl.formatMessage({
  id: 'configuration.constants.update',
  defaultMessage: 'Update',
});

export const USING = intl.formatMessage({
  id: 'configuration.constants.using',
  defaultMessage: 'Using',
});

export const ON_FILE = intl.formatMessage({
  id: 'configuration.constants.onFile',
  defaultMessage: 'on file',
});

export const SAVE = intl.formatMessage({
  id: 'configuration.constants.save',
  defaultMessage: 'Save',
});

export const PREVIEW = intl.formatMessage({
  id: 'configuration.constants.preview',
  defaultMessage: 'Preview',
});

export const IMAGE_HEADING_BACKGROUND = intl.formatMessage({
  id: 'imageStepHeadings.background',
  defaultMessage: 'background',
});

export const IMAGE_HEADING_EFFECTS = intl.formatMessage({
  id: 'imageStepHeadings.effects',
  defaultMessage: 'effects',
});

export const IMAGE_HEADING_CROP = intl.formatMessage({
  id: 'imageStepHeadings.crop',
  defaultMessage: 'crop',
});

export const IMAGE_HEADING_PHOTO = intl.formatMessage({
  id: 'imageStepHeadings.photo',
  defaultMessage: 'photo',
});

export const HIDE = intl.formatMessage({
  id: 'imageNodeBgDisplay.hide',
  defaultMessage: 'Hide',
});

export const SHOW = intl.formatMessage({
  id: 'imageNodeBgDisplay.show',
  defaultMessage: 'Show',
});
