import { CheckIcon } from '@chakra-ui/icons';
import { AspectRatio, Flex, Heading, Icon } from '@chakra-ui/react';
import React from 'react';

interface Props {
  isActive: boolean;
  isComplete: boolean;
  stepNumber: number;
  stepTitle: string;
}

const FacialRecognitionStepHeader = ({ isActive, isComplete, stepNumber, stepTitle }: Props) => (
  <Flex
    alignItems="center"
    color={isActive ? 'black' : isComplete ? 'successGreen' : 'grey.5'}
    maxHeight="40px"
  >
    <AspectRatio
      border="1px solid"
      borderColor={isActive ? 'black' : isComplete ? 'transparent' : 'grey.5'}
      borderRadius="50%"
      borderWidth={isActive ? 2 : isComplete ? 0 : 1}
      marginRight={isActive ? 4 : isComplete ? 0 : 4}
      maxWidth="36px"
      ratio={1}
      width="36px"
    >
      {isActive || !isComplete ? (
        <Heading fontWeight="bold" fontSize="xl">
          {stepNumber}
        </Heading>
      ) : (
        <Flex>
          <Icon as={CheckIcon} boxSize={5} />
        </Flex>
      )}
    </AspectRatio>

    <Heading fontSize={{ base: 'lg', lg: `${isActive ? '2xl' : 'xl'}` }} noOfLines={1}>
      {stepTitle}
    </Heading>
  </Flex>
);

export default FacialRecognitionStepHeader;
