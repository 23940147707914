import { Flex, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectBackgroundsMap } from '../../../../redux/selectors/background.selectors';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../redux/store';
import Thumbnail from '../../../../shared/components/Thumbnail';
import { UniqueImage } from '../../../../shared/types/image';
import { Params } from '../../../../shared/types/router';

interface Props {
  imageSet: UniqueImage[];
  visitKey: string;
}

/**
 * Renders a row of thumbnails of each unique image used in the cart item.
 * The row wraps when it reaches the edge of its container
 */
const CartItemThumbnails = ({ imageSet, visitKey }: Props) => {
  const { key } = useParams<Params>();
  const { images } = useAppSelector(state => selectGallery(state, visitKey || key));
  const backgroundsMap = useSelector(selectBackgroundsMap);

  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

  const renderThumbnail = (name: string, backgroundID?: number) => {
    const image = images[name];
    if (!image) {
      return;
    }

    const { internalName, sources } = image;
    const background = backgroundID ? backgroundsMap[backgroundID]?.sources.thumb : undefined;
    const thumbnailSize = isMobile ? '45px' : '57px';

    return (
      <Flex
        height={thumbnailSize}
        justifyContent="center"
        key={`${internalName}-${backgroundID}`}
        marginBottom="15px"
        marginRight="15px"
        width="auto"
        maxWidth={thumbnailSize}
      >
        <Thumbnail
          background={background}
          containerStyles={{ alignItems: 'center', minWidth: 'auto' }}
          hideSpinner
          maxHeight="100%"
          maxW="100%"
          maxWidth="unset"
          src={sources.thumb}
        />
      </Flex>
    );
  };

  const renderThumbnails = () =>
    imageSet.map(image => renderThumbnail(image.image, image.backgroundID));

  return (
    <Flex
      marginBottom={{ base: -1.5, md: 0 }}
      marginTop={{ base: 1.5, md: 0 }}
      paddingLeft={{ base: 1, md: 0 }}
      wrap="wrap"
    >
      {renderThumbnails()}
    </Flex>
  );
};

export default CartItemThumbnails;
