import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';

interface Props extends InputProps {
  description?: string;
  invalidMessage?: string;
  name: string;
  onCancel?: any;
  showCancel?: boolean;
  inputLabel: string;
  hideAsterisk?: boolean; // To control when the asterisk is displayed for required fields.
}

const FloatingLabelInput = ({
  autoFocus,
  description,
  invalidMessage,
  isInvalid,
  isRequired,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  name,
  onBlur,
  onCancel,
  onFocus,
  inputLabel = '',
  placeholder = '',
  showCancel = false,
  value,
  width,
  hideAsterisk = false,
  ...rest
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // The autoFocus property on the input will take care of its own focus
    // state if it's initially true. If the autoFocus property switches from
    // false to true however, it won't actually focus on the re-render.
    // This useEffect essentially accomplishes that feature.
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [autoFocus]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }
  };
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(e);
    }
  };

  return (
    <Box
      alignContent="center"
      display="flex"
      flexDirection="column"
      margin={margin}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      position="relative"
      width={width}
    >
      <Text
        as="label"
        color={isInvalid ? 'error' : 'textLabel'}
        fontSize="16px"
        maxWidth={width}
        noOfLines={1}
        fontFamily="Inter"
      >
        {inputLabel}
        {isRequired && !hideAsterisk && (
          <Text as="span" color="error" paddingLeft="2">
            *
          </Text>
        )}
      </Text>
      <InputGroup>
        <Input
          ref={ref}
          autoFocus={autoFocus}
          isInvalid={isInvalid}
          name={name}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          variant="base"
          placeholder={placeholder}
          {...rest}
        />
        <InputRightElement>
          {description && (
            <Tooltip label={description} aria-label={description} background="grey.5">
              <InfoOutlineIcon color="grey.3" marginRight="112px" />
            </Tooltip>
          )}
          {showCancel && (
            <Button
              data-test="Cancel-button"
              variant="link"
              color="#737475"
              onClick={onCancel}
              position="absolute"
              right={0}
              width="72px"
              height="35px"
              minWidth="72px"
              minHeight="35px"
            >
              Cancel
            </Button>
          )}
        </InputRightElement>
      </InputGroup>

      {isInvalid && (
        <Text
          data-test="required-to-fill-error"
          color="error"
          fontSize="12px"
          marginLeft="5px"
          marginTop="8px"
          fontFamily="Inter"
        >
          {invalidMessage || `Please enter a valid ${placeholder}`}
        </Text>
      )}
    </Box>
  );
};

export default FloatingLabelInput;
