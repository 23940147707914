import { CatalogProductNode } from 'iq-product-render';
import { compact } from 'lodash';
import {
  ImageRequirementType,
  ProductOption,
  ShopBackgroundSet,
  ShopImage,
  ShopPackage,
  ShopProduct,
} from '../../../shop-api-client';
import { ShopProductNodes } from '../../../shop-api-client/models/ShopProductNodes';
import { PriceSheet } from '../../redux/slices/gallery.slice';
import { isJobImageNode } from '../../redux/thunks/utils';
import { FILTER, SEARCH } from '../../shared/constants';
import { intl } from '../../shared/constants/intl';
import { formatCurrency } from '../../shared/utils';
import { UPDATE } from './Configuration/constants';
import {
  ADD_TO_CART,
  CUSTOMIZE,
  ITEM_UNAVAILABLE,
  ITEM_UNAVAILABLE_MOBILE,
  SUBJECT_FIELDS,
} from './constants';

export const DYNAMIC_DATA_SUBJECT = {
  firstName: SUBJECT_FIELDS['firstName'],
  lastName: SUBJECT_FIELDS['lastName'],
  onlineCode: SUBJECT_FIELDS['onlineCode'],
  subjectID: SUBJECT_FIELDS['subjectID'],
  address: SUBJECT_FIELDS['address'],
  city: SUBJECT_FIELDS['city'],
  state: SUBJECT_FIELDS['state'],
  zip: SUBJECT_FIELDS['zip'],
  country: SUBJECT_FIELDS['country'],
  phone1: SUBJECT_FIELDS['phone1'],
  phone2: SUBJECT_FIELDS['phone2'],
  email: SUBJECT_FIELDS['email'],
  notes: SUBJECT_FIELDS['notes'],
  organization: SUBJECT_FIELDS['organization'],
  referenceNumber: SUBJECT_FIELDS['referenceNumber'],
  mother: SUBJECT_FIELDS['mother'],
  father: SUBJECT_FIELDS['father'],
  year: SUBJECT_FIELDS['year'],
  grade: SUBJECT_FIELDS['grade'],
  teacher: SUBJECT_FIELDS['teacher'],
  homeRoom: SUBJECT_FIELDS['homeRoom'],
  personalization: SUBJECT_FIELDS['personalization'],
  jerseyNumber: SUBJECT_FIELDS['jerseyNumber'],
  custom1: SUBJECT_FIELDS['custom1'],
  custom2: SUBJECT_FIELDS['custom2'],
  custom3: SUBJECT_FIELDS['custom3'],
  custom4: SUBJECT_FIELDS['custom4'],
  custom5: SUBJECT_FIELDS['custom5'],
  custom6: SUBJECT_FIELDS['custom6'],
  custom7: SUBJECT_FIELDS['custom7'],
  custom8: SUBJECT_FIELDS['custom8'],
  custom9: SUBJECT_FIELDS['custom9'],
  custom10: SUBJECT_FIELDS['custom10'],
  custom11: SUBJECT_FIELDS['custom11'],
  custom12: SUBJECT_FIELDS['custom12'],
  custom13: SUBJECT_FIELDS['custom13'],
  custom14: SUBJECT_FIELDS['custom14'],
  custom15: SUBJECT_FIELDS['custom15'],
  custom16: SUBJECT_FIELDS['custom16'],
  custom17: SUBJECT_FIELDS['custom17'],
  custom18: SUBJECT_FIELDS['custom18'],
  custom19: SUBJECT_FIELDS['custom19'],
  custom20: SUBJECT_FIELDS['custom20'],
  photographer: SUBJECT_FIELDS['photographer'],
  photoSessionDate: SUBJECT_FIELDS['photoSessionDate'],
  expirationDate: SUBJECT_FIELDS['expirationDate'],
};

export const getBackgroundFromSets = (
  backgroundSets: ShopBackgroundSet[],
  backgroundID: number | undefined,
) => {
  if (backgroundID) {
    for (const set of backgroundSets) {
      for (const bg of set.backgrounds) {
        if (bg.id === backgroundID) {
          return bg;
        }
      }
    }
  }
};

export const getCustomizeLabel = (
  unavailable: boolean,
  canAddToCart: boolean,
  isMobile: boolean | undefined,
  isUpdating?: boolean,
) => {
  if (unavailable) {
    return isMobile ? ITEM_UNAVAILABLE_MOBILE : ITEM_UNAVAILABLE;
  }
  if (canAddToCart) {
    return isUpdating ? UPDATE : ADD_TO_CART;
  }
  return CUSTOMIZE;
};

interface ItemParam {
  name: string | null;
  description: string | null;
  height?: number;
  width?: number;
}

export const getFilteredProducts = (
  activeCategoryID: string,
  { categories, productCategoryMap, products, offersStatusMap }: PriceSheet,
  searchParams: URLSearchParams,
) => {
  // Return boolean determined by whether item meets search criteria
  const meetsCriteria = (query: string, item: ItemParam, itemType: 'option' | 'product') => {
    const criteriaList: (string | number | null | undefined)[] = [item.name, item.description];
    if (itemType === 'product' && item.height) {
      criteriaList.push(...[item.height, item.width]);
    }
    return compact(criteriaList).join(' ').toLowerCase().includes(query);
  };

  // Return boolean determined by whether an option of list of options meets search criteria
  const optionsMeetCriteria = (options: ProductOption[], query: string) =>
    options.some(
      option =>
        meetsCriteria(query, option, 'option') ||
        (option.type === 'selection' &&
          option.selections.some(s => meetsCriteria(query, s, 'option'))),
    );

  const filters = searchParams.getAll(FILTER);
  const hasLockedFilter = filters.includes('locked');
  const hasUnlockedFilter = filters.includes('unlocked');
  const searchString = searchParams.get(SEARCH)?.split('+').join(' ').toLowerCase() || '';

  let filteredProducts: number[] = productCategoryMap[activeCategoryID];

  if (searchString) {
    filteredProducts = Object.values(products).reduce<number[]>((result, item) => {
      let subItemMeetsCriteria = false;
      if (item.type === 'package' || item.type === 'package-byop') {
        subItemMeetsCriteria = item.availableProducts.some(
          p =>
            meetsCriteria(searchString, p, 'product') ||
            optionsMeetCriteria(p.options, searchString),
        );
      }

      if (
        subItemMeetsCriteria ||
        meetsCriteria(searchString, item, 'product') ||
        optionsMeetCriteria(item.options, searchString)
      ) {
        result.push(item.id);
      }

      return result;
    }, []);
  }

  return filteredProducts.reduce<(ShopProduct | ShopPackage)[]>((result, productID) => {
    const category = categories[products[productID].categoryID];

    if (
      !filters.length ||
      filters.includes(products[productID].type) ||
      (hasLockedFilter && offersStatusMap[category.id]?.isLocked) ||
      (hasUnlockedFilter && !offersStatusMap[category.id]?.isLocked)
    ) {
      result.push(products[productID]);
    }
    return result;
  }, []);
};

export const getNodesForItem = (
  item: ShopPackage | ShopProduct,
  productNodeMap: ShopProductNodes,
) => {
  if (item.type === 'package' || item.type === 'package-byop') {
    return item.availableProducts.reduce<CatalogProductNode[]>((result, product) => {
      if (productNodeMap[product.catalogProductID]) {
        result.push(...productNodeMap[product.catalogProductID]);
      }
      return result;
    }, []);
  }
  return productNodeMap[item.catalogProductID] || [];
};

export const getNodeMapForCatalogProduct = (
  catalogProductID: number,
  productNodeMap: Record<string, CatalogProductNode[]>,
) => {
  const nodes = productNodeMap[catalogProductID] || [];
  return nodes.reduce<Record<string, CatalogProductNode>>((map, node) => {
    map[node.id] = node;
    return map;
  }, {});
};

export const getPackageItemMap = (pkg: ShopPackage) => {
  return pkg.availableProducts.reduce<Record<string, ShopProduct>>((map, product) => {
    map[product.id] = product;
    return map;
  }, {});
};

/**
 * Returns poses details, based on the Studio's pose requirements for the package
 */
export const getPoseDescription = (product: ShopPackage | ShopProduct, currency: string | null) => {
  if (product.type !== 'package' && product.type !== 'package-byop') {
    return '';
  }

  // If additional poses are allowed, but cost nothing, that means you have unlimited poses:
  if (product.allowAdditionalPoses && product.additionalPoseFeeType === 'noSurcharge') {
    return intl.formatMessage({
      id: 'products.utils.unlimitedPoses',
      defaultMessage: 'Unlimited poses. No additional cost per pose.',
    });
  }

  // Otherwise figure out how many poses are included
  const poses = product.posesIncluded === 1 ? '1 pose' : `${product.posesIncluded} poses`;
  let description = intl.formatMessage(
    {
      id: 'products.utils.posesIncluded',
      defaultMessage: '{poses} included with purchase.',
    },
    { poses },
  );

  // And show if additional poses can be purchased
  if (product.allowAdditionalPoses) {
    `${formatCurrency(product.additionalPoseFee, currency)}${
      product.additionalPoseFeeType === 'perImage' ? '/pose' : ''
    }`;
    const amount = `${formatCurrency(product.additionalPoseFee, currency)}${
      product.additionalPoseFeeType === 'perImage' ? '/pose' : ''
    }`;
    const feeType = `${product.additionalPoseFeeType === 'perImage' ? '/pose.' : '.'}`;
    const additionalPoses = intl.formatMessage(
      {
        id: 'products.utils.additionalPoses',
        defaultMessage: 'Additional poses can be added for {amount}',
      },
      { amount, feeType },
    );
    description += ` ${additionalPoses}`;
  }

  return description;
};

export const getProductDescription = (product: ShopPackage | ShopProduct) =>
  product.description ||
  intl.formatMessage({
    id: 'products.utils.descriptionFallback',
    defaultMessage: 'No additional product information available',
  });

export const getProductNodeMap = (nodes: CatalogProductNode[]) => {
  return nodes.reduce<Record<string, CatalogProductNode>>((result, node) => {
    result[node.id] = node;
    return result;
  }, {});
};

export const hasEditableTextNodes = (nodes: CatalogProductNode[] = []) =>
  nodes.some(n => n.type === 'text' && !n.locked);

export const isConfigurableImageNode = (node: CatalogProductNode) => {
  return node.type === 'image' && node.defaultImage !== 'blank' && !isJobImageNode(node);
};

export const hasConfigurableImageNodes = (nodes: CatalogProductNode[] = []) =>
  nodes.some(n => isConfigurableImageNode(n));

export const isNodeWithBg = (node: CatalogProductNode) => {
  return (
    node.type === 'image' &&
    node.defaultImage !== 'blank' &&
    !isJobImageNode(node) &&
    !node.skipBackgroundSelection
  );
};

export const hasImageNodesWithBg = (nodes: CatalogProductNode[] = []) => {
  return nodes.some(n => isNodeWithBg(n));
};

export const hasRequiredProductOptions = (item: ShopPackage | ShopProduct): boolean => {
  if (item.type === 'package' || item.type === 'package-byop') {
    return item.availableProducts.some(p => hasRequiredProductOptions(p));
  }
  return item.options.some(o => o.requirementType === 'required');
};

export const validateImageRequirement = (
  image: ShopImage,
  imageRequirement?: ImageRequirementType | null,
) => {
  if (imageRequirement === 'group') {
    return !!image.group;
  }
  if (imageRequirement === 'nonGroup') {
    return !image.group;
  }
  return true;
};

export const getFallbackProductImageSrc = (
  fallbackImage:
    | 'collection'
    | 'digital-download-multi-image'
    | 'digital-download-single-image'
    | 'non-print-product'
    | 'package-byop'
    | 'package'
    | 'print-product',
) => {
  return `/assets/images/product-fallbacks/${fallbackImage}.jpg`;
};

export const getProductImageSrc = (product: {
  type: (ShopProduct | ShopPackage)['type'];
  maxImages?: number;
}) => {
  if (product.type === 'nonPrintProduct') {
    return getFallbackProductImageSrc('non-print-product');
  }
  if (product.type === 'collection') {
    return getFallbackProductImageSrc('collection');
  }
  if (product.type === 'imageDownload') {
    if (product.maxImages === 1) {
      return getFallbackProductImageSrc('digital-download-single-image');
    }
    if (product.maxImages && product.maxImages > 1) {
      return getFallbackProductImageSrc('digital-download-multi-image');
    }
  }
  if (product.type === 'package') {
    return getFallbackProductImageSrc('package');
  }
  if (product.type === 'package-byop') {
    return getFallbackProductImageSrc('package-byop');
  }
  return getFallbackProductImageSrc('print-product');
};

export const getFirstAssignedImage = (shopItem: ShopPackage | ShopProduct) => {
  if ('availableProducts' in shopItem) {
    const filteredSubItems = shopItem.availableProducts.filter(p => !!p.image);
    const subItemImages = filteredSubItems.map(subItem => subItem.image || '');

    const displayImages = shopItem.image ? [shopItem.image] : [];
    displayImages.push(...subItemImages);

    if (!displayImages.length) {
      return '';
    }

    return displayImages[0];
  }
  if (!shopItem.images?.length) {
    return '';
  }
  return shopItem.images[0];
};
