import { intl } from './intl';

export const BACKGROUND = intl.formatMessage({
  id: 'imageStepHeadings.background',
  defaultMessage: 'background',
});

export const CROP = intl.formatMessage({
  id: 'imageStepHeadings.crop',
  defaultMessage: 'crop',
});

export const EFFECTS = intl.formatMessage({
  id: 'imageStepHeadings.effects',
  defaultMessage: 'effects',
});

export const PHOTO = intl.formatMessage({
  id: 'imageStepHeadings.photo',
  defaultMessage: 'photo',
});
