import { intl } from '../../shared/constants';

export const ADD_TO_CART = intl.formatMessage({
  id: 'productCard.addToCart',
  defaultMessage: 'Add to Cart',
});

export const CUSTOMIZE = intl.formatMessage({
  id: 'products.constants.customize',
  defaultMessage: 'Customize',
});

export const DESCRIPTION = intl.formatMessage({
  id: 'products.constants.description',
  defaultMessage: 'DESCRIPTION',
});

export const POSE_OPTIONS = intl.formatMessage({
  id: 'products.constants.poseOptions',
  defaultMessage: 'POSE OPTIONS',
});

export const ITEM_UNAVAILABLE = intl.formatMessage({
  id: 'products.constants.itemUnavailable',
  defaultMessage: 'Item currently unavailable',
});

export const ITEM_UNAVAILABLE_MOBILE = intl.formatMessage({
  id: 'products.constants.itemUnavailableMobile',
  defaultMessage: 'Item Unavailable',
});

export const PRODUCTS_GUTTER = 10;

// Product filterable labels
export const INTL_AVAILABILITY_FILTER_LABELS: { [key: string]: string } = {
  unlocked: intl.formatMessage({
    id: 'constants.unlocked',
    defaultMessage: 'Unlocked',
  }),
  locked: intl.formatMessage({
    id: 'constants.locked',
    defaultMessage: 'Locked',
  }),
};
export const PRODUCT_TYPE_FILTER_LABELS: { [key: string]: string } = {
  package: intl.formatMessage({
    id: 'constants.packages',
    defaultMessage: 'Packages',
  }),
  'package-byop': intl.formatMessage({
    id: 'constants.byop',
    defaultMessage: 'Build Your Own Packages',
  }),
  product: intl.formatMessage({
    id: 'constants.prints',
    defaultMessage: 'Prints',
  }),
  imageDownload: intl.formatMessage({
    id: 'constants.downloads',
    defaultMessage: 'Photo Downloads',
  }),
  collection: intl.formatMessage({
    id: 'constants.collection',
    defaultMessage: 'Collections',
  }),
  nonPrintProduct: intl.formatMessage({
    id: 'constants.nonPrint',
    defaultMessage: 'Non Print Products',
  }),
};

export const SUBJECT_FIELDS: { [key: string]: string } = {
  firstName: intl.formatMessage({
    id: 'fields.firstName',
    defaultMessage: 'First Name',
  }),
  lastName: intl.formatMessage({
    id: 'fields.lastName',
    defaultMessage: 'Last Name',
  }),
  onlineCode: intl.formatMessage({
    id: 'fields.onlineCode',
    defaultMessage: 'Online Code',
  }),
  subjectID: intl.formatMessage({
    id: 'fields.subjectID',
    defaultMessage: 'Subject ID',
  }),
  address: intl.formatMessage({
    id: 'fields.address',
    defaultMessage: 'Address',
  }),
  city: intl.formatMessage({
    id: 'fields.city',
    defaultMessage: 'City',
  }),
  state: intl.formatMessage({
    id: 'fields.state',
    defaultMessage: 'State',
  }),
  zip: intl.formatMessage({
    id: 'fields.zip',
    defaultMessage: 'Zip/Postal Code',
  }),
  country: intl.formatMessage({
    id: 'fields.country',
    defaultMessage: 'Country',
  }),
  phone1: intl.formatMessage({
    id: 'fields.phone1',
    defaultMessage: 'Phone 1',
  }),
  phone2: intl.formatMessage({
    id: 'fields.phone2',
    defaultMessage: 'Phone 2',
  }),
  email: intl.formatMessage({
    id: 'fields.email',
    defaultMessage: 'Email(s)',
  }),
  notes: intl.formatMessage({
    id: 'fields.notes',
    defaultMessage: 'Notes',
  }),
  organization: intl.formatMessage({
    id: 'fields.organization',
    defaultMessage: 'Organization',
  }),
  referenceNumber: intl.formatMessage({
    id: 'fields.referenceNumber',
    defaultMessage: 'Reference Number',
  }),
  mother: intl.formatMessage({
    id: 'fields.mother',
    defaultMessage: 'Mother',
  }),
  father: intl.formatMessage({
    id: 'fields.father',
    defaultMessage: 'Father',
  }),
  year: intl.formatMessage({
    id: 'fields.year',
    defaultMessage: 'Year',
  }),
  grade: intl.formatMessage({
    id: 'fields.grade',
    defaultMessage: 'Grade',
  }),
  teacher: intl.formatMessage({
    id: 'fields.teacher',
    defaultMessage: 'Teacher',
  }),
  homeRoom: intl.formatMessage({
    id: 'fields.homeRoom',
    defaultMessage: 'Home Room',
  }),
  personalization: intl.formatMessage({
    id: 'fields.personalization',
    defaultMessage: 'Personalization',
  }),
  jerseyNumber: intl.formatMessage({
    id: 'fields.jerseyNumber',
    defaultMessage: 'Jersey #',
  }),
  custom1: intl.formatMessage({
    id: 'fields.custom1',
    defaultMessage: 'Custom Data 1',
  }),
  custom2: intl.formatMessage({
    id: 'fields.custom2',
    defaultMessage: 'Custom Data 2',
  }),
  custom3: intl.formatMessage({
    id: 'fields.custom3',
    defaultMessage: 'Custom Data 3',
  }),
  custom4: intl.formatMessage({
    id: 'fields.custom4',
    defaultMessage: 'Custom Data 4',
  }),
  custom5: intl.formatMessage({
    id: 'fields.custom5',
    defaultMessage: 'Custom Data 5',
  }),
  custom6: intl.formatMessage({
    id: 'fields.custom6',
    defaultMessage: 'Custom Data 6',
  }),
  custom7: intl.formatMessage({
    id: 'fields.custom7',
    defaultMessage: 'Custom Data 7',
  }),
  custom8: intl.formatMessage({
    id: 'fields.custom8',
    defaultMessage: 'Custom Data 8',
  }),
  custom9: intl.formatMessage({
    id: 'fields.custom9',
    defaultMessage: 'Custom Data 9',
  }),
  custom10: intl.formatMessage({
    id: 'fields.custom10',
    defaultMessage: 'Custom Data 10',
  }),
  custom11: intl.formatMessage({
    id: 'fields.custom11',
    defaultMessage: 'Custom Data 11',
  }),
  custom12: intl.formatMessage({
    id: 'fields.custom12',
    defaultMessage: 'Custom Data 12',
  }),
  custom13: intl.formatMessage({
    id: 'fields.custom13',
    defaultMessage: 'Custom Data 13',
  }),
  custom14: intl.formatMessage({
    id: 'fields.custom14',
    defaultMessage: 'Custom Data 14',
  }),
  custom15: intl.formatMessage({
    id: 'fields.custom15',
    defaultMessage: 'Custom Data 15',
  }),
  custom16: intl.formatMessage({
    id: 'fields.custom16',
    defaultMessage: 'Custom Data 16',
  }),
  custom17: intl.formatMessage({
    id: 'fields.custom17',
    defaultMessage: 'Custom Data 17',
  }),
  custom18: intl.formatMessage({
    id: 'fields.custom18',
    defaultMessage: 'Custom Data 18',
  }),
  custom19: intl.formatMessage({
    id: 'fields.custom19',
    defaultMessage: 'Custom Data 19',
  }),
  custom20: intl.formatMessage({
    id: 'fields.custom20',
    defaultMessage: 'Custom Data 20',
  }),
  photographer: intl.formatMessage({
    id: 'fields.photographer',
    defaultMessage: 'Photographer',
  }),
  photoSessionDate: intl.formatMessage({
    id: 'fields.photoSessionDate',
    defaultMessage: 'Photo Session Date',
  }),
  expirationDate: intl.formatMessage({
    id: 'fields.expirationDate',
    defaultMessage: 'Expiration Date',
  }),
};
