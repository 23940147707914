import { Image, ImageProps } from '@chakra-ui/image';
import { Flex, FlexProps } from '@chakra-ui/layout';
import { chakra, Spinner } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FADE_IN_KEYFRAMES } from '../../constants/style.constants';
import { getImageBuffer } from '../../utils';
import ActiveImageOverlay from '../ActiveImageOverlay';
import ImageBadge from '../ImageBadge';
import ImageDisplayName from '../ImageDisplayName';

interface Props extends ImageProps {
  background?: string;
  badgeSize?: string;
  badgeText?: string;
  containerStyles?: FlexProps;
  displayName?: string;
  error?: boolean;
  flex?: string;
  fontSize?: string;
  height?: string | number;
  hideSpinner?: boolean;
  imageWidth?: string;
  isActive?: boolean;
  margin?: string;
  onImageLoad?(): void;
  placeholderH?: string | number;
  placeholderW?: string | number;
  showBadge?: boolean;
  showNames?: boolean;
  textWidth?: number;
  width?: string | number;
  lazyLoad?: boolean;
  lazyLoadOptions?: any;
}

const Thumbnail = ({
  background,
  badgeSize,
  badgeText,
  containerStyles,
  displayName,
  error = false,
  flex,
  fontSize,
  height,
  hideSpinner = false,
  imageWidth,
  isActive,
  margin,
  onImageLoad,
  placeholderH = '150px',
  placeholderW = '100px',
  showBadge = false,
  showNames,
  src,
  textWidth,
  width,
  lazyLoad = true,
  lazyLoadOptions,
  ...rest
}: Props) => {
  const [bg, setBg] = useState<string | undefined>();
  const [imgSrc, setImgSrc] = useState<string | ArrayBuffer | null>();
  const [loading, setLoading] = useState(true);
  const [prevBg, setPrevBg] = useState<string | undefined>();
  const [showBackground, setShowBackground] = useState(false);
  const thumbRef = useRef<HTMLDivElement>(null);

  const fadeIn = `${FADE_IN_KEYFRAMES} .5s`;

  useEffect(() => {
    if (!src) {
      return;
    }
    let isUnmounted = false;

    const loadSrc = async () => {
      const buffer = await getImageBuffer(src);
      if (isUnmounted) {
        return;
      }
      setImgSrc(buffer?.toString());
      setLoading(false);
    };

    // TODO: convert to hook if other elements need intersectobs api based lazy loading
    // intersection observer for lazy loading
    // check compatibility
    const isBrowserCompatible = 'IntersectionObserver' in window;
    let observer: IntersectionObserver | null = null;
    if (isBrowserCompatible && lazyLoad) {
      observer = new IntersectionObserver(
        img => {
          // if intersecting = true, load src + unobserve
          if (img[0].isIntersecting && thumbRef.current) {
            loadSrc();
            observer?.unobserve(thumbRef.current as Element);
          }
        },
        lazyLoadOptions || { root: null, rootMargin: '20%', threshold: 0 },
      );

      if (thumbRef?.current) {
        observer.observe(thumbRef.current as Element);
      }
    } else {
      loadSrc();
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
      isUnmounted = true;
    };
  }, [src, lazyLoad, lazyLoadOptions]);

  useEffect(() => {
    if (!background) {
      return;
    }

    setBg(prevBg => {
      setPrevBg(prevBg);
      return background;
    });

    setShowBackground(prevShowBg => !!background || prevShowBg);
  }, [background]);

  const ignoreRightClick = (e: React.MouseEvent) => e.preventDefault();

  const renderLoadingState = () => {
    if (hideSpinner) {
      return;
    }
    return (
      <Flex
        align="center"
        alignSelf="center"
        backgroundColor="white"
        height={height}
        justify="center"
        minH={placeholderH}
        minW={placeholderW}
        width={imageWidth || width}
      >
        <Spinner color="grey.3" />
      </Flex>
    );
  };

  return (
    <Flex
      alignItems="start"
      flex={flex}
      height={height}
      margin={margin}
      onContextMenu={ignoreRightClick}
      position="relative"
      width={imageWidth}
      minWidth="60px" // min width for lazy loading
      ref={thumbRef}
      {...containerStyles}
    >
      {(isActive || error) && !loading && (
        <ActiveImageOverlay
          backgroundColor={error ? 'transparent' : 'IQHIGHLIGHT'}
          badgeSize={badgeSize}
          borderColor={error ? 'error' : 'IQBLUE'}
          cursor="pointer"
          hideIcon={error}
          iconFontSize={fontSize}
        />
      )}
      {!loading ? (
        <>
          <Image
            data-test="specific-photo-selection"
            animation={fadeIn}
            flex={flex}
            height={height}
            maxWidth={imageWidth || '100%'}
            onLoad={onImageLoad}
            src={imgSrc?.toString()}
            width={width}
            zIndex={0}
            {...rest}
          />
          {showBackground && bg && (
            <>
              <chakra.img
                animation={fadeIn}
                height="100%"
                key={bg}
                position="absolute"
                src={bg}
                width="100%"
                zIndex={-1}
              />
              {prevBg && (
                <chakra.img
                  height="100%"
                  position="absolute"
                  src={prevBg}
                  width="100%"
                  zIndex={-2}
                />
              )}
            </>
          )}
        </>
      ) : (
        renderLoadingState()
      )}

      {showNames && (
        <ImageDisplayName imageName={displayName} width={textWidth ? `${textWidth}px` : '100%'} />
      )}

      {showBadge && !loading && (
        <ImageBadge
          badgeSize={badgeSize}
          data-test="thumbnail-image-badge"
          fontSize="40%"
          mx="2%"
          my="2%"
          position="absolute"
          right="0"
          text={badgeText}
          top="0"
        />
      )}
    </Flex>
  );
};

export default Thumbnail;
