import { FiColumns, FiGrid, FiSquare } from 'react-icons/fi';
import { RiLayoutMasonryLine } from 'react-icons/ri';

import { intl } from './intl';
export * from './aria-labels.constants';
export * from './carts.constants';
export * from './fields.constants';
export * from './headings.constants';
export * from './image.constants';
export * from './intl';
export * from './z-index.constants';

// Proofing
export const PREORDER = 'preorder';

// Ondemand Image Sizes
export const BACKGROUND_THUMB = 200;
export const FULL_IMAGE_SIZE = 1200;
export const ONDEMAND_SIZE_600 = 600;
export const ONDEMAND_THUMB = 220;

// Grid
export const GRID_MAX_WIDTH = 1440;

// Grid Thumbnail Sizes
export const GRID_THUMB_LG = 600;
export const GRID_THUMB_MD = 400;
export const GRID_THUMB_SM = 200;

//Grid Modes
export const LARGE = 'large';
export const MASONRY = 'masonry';
export const MEDIUM = 'medium';
export const SMALL = 'small';

// Grid layout icons with corresponding thumbnail size
export const GRID_ICON_LARGE = { icon: FiSquare, name: LARGE };
export const GRID_ICON_MASONRY = { icon: RiLayoutMasonryLine, name: MASONRY };
export const GRID_ICON_MEDIUM = { icon: FiColumns, name: MEDIUM };
export const GRID_ICON_SMALL = { icon: FiGrid, name: SMALL };

// Query Params
export const FILTER = 'filter';
export const SEARCH = 'search';

// URLs
export const NODE_ENV = process.env.NODE_ENV;
export const S3_CATALOG_BUCKET = NODE_ENV === 'development' ? 'iq-test-catalog' : 'iq-catalog';

export const CATALOG_URL = `https://s3.amazonaws.com/${S3_CATALOG_BUCKET}`;

// Response status codes
export const FORBIDDEN = 403;

export const ADD_TO = intl.formatMessage({
  id: 'option.addTo',
  defaultMessage: 'Add to',
});

export const CHOOSE = intl.formatMessage({
  id: 'option.choose',
  defaultMessage: 'Choose',
});

export const ITEM = intl.formatMessage({
  id: 'option.item',
  defaultMessage: 'Item',
});

export const OPTION = intl.formatMessage({
  id: 'option.option',
  defaultMessage: 'Option',
});

export const PHOTO = intl.formatMessage({
  id: 'option.photo',
  defaultMessage: 'Photo',
});
