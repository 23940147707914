import { Box, Button, chakra, Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  OptionForSelectionOption,
  PriceSheetOption,
  ProductOption,
} from '../../../../shop-api-client';
import ProductImage from '../../../features/Products/ProductImage';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import { useAppSelector } from '../../../redux/store';
import { ADD_TO, CHOOSE, FREE, ITEM, OPTION, PHOTO } from '../../constants';
import { formatCurrency, getImageBuffer } from '../../utils';
import CompareChoices from './CompareChoices';
import SelectionOption from './SelectionOption';
import TextOption from './TextOption';
import ToggleOption from './ToggleOption';

interface Props {
  /** only used for text product options that need to lookup via rendererproduct */
  catalogProductID?: number;
  /** for text product options that have a currently saved text string */
  defaultText?: string;
  expanded?: boolean;
  /** only for text inputs */
  focused?: boolean;
  heading: string;
  hideTextSave?: boolean;
  /** currently only used on order options */
  isInvalid?: boolean;
  option: PriceSheetOption | ProductOption;
  /**only used for multi-image image option selection */
  selectionBadges?: Record<number, number>;
  selectionID?: number | false;
  setSelectionID(selectionID: number | false, value?: string): void;
  /**
   * render red * requiredness (mostly for order options)
   */
  showRequired?: boolean;
  /** compact vs full size version */
  useCompact?: boolean;
}

/**
 * renders what we know as an option group
 */
const Option = ({
  catalogProductID,
  defaultText,
  expanded,
  focused,
  heading,
  hideTextSave,
  isInvalid,
  option,
  selectionBadges,
  selectionID,
  setSelectionID,
  showRequired,
  useCompact,
}: Props) => {
  const { currency } = useAppSelector(selectAccount);

  const [displayImageBuffer, setDisplayImageBuffer] = useState<string | ArrayBuffer | null>(null);
  const [openOptional, setOpenOptional] = useState(expanded); // one time toggle (TBD) for optional options
  const [showCompare, setShowCompare] = useState(false); // renders modal to see details on options (selection options only)

  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const intl = useIntl();

  // # of char we default truncate the description to
  const truncateAt = isMobile ? 70 : 135;
  const [truncate, setTruncate] = useState(
    option.description && option.description.length > truncateAt ? truncateAt : 0,
  );

  const isRequired = option.requirementType === 'required';
  const optionType = option.optionGroupType;
  const compact = useCompact || !isRequired;

  const pleaseMakeASelection = intl.formatMessage({
    id: 'option.pleaseMakeASelection',
    defaultMessage: 'Please make a selection',
  });
  const compare = intl.formatMessage({
    id: 'option.compare',
    defaultMessage: 'Compare',
  });
  const showMore = intl.formatMessage({
    id: 'option.showMore',
    defaultMessage: 'Show More',
  });

  const greenText = intl.formatMessage(
    {
      id: 'option.addThing',
      defaultMessage: '+ {addOrChoose} {photoOrItem}',
    },
    {
      addOrChoose: option.type === 'selection' ? CHOOSE : ADD_TO,
      photoOrItem: option.type === 'selection' ? OPTION : optionType === 'image' ? PHOTO : ITEM,
    },
  );

  // Initializes with the Option's display image, or if a selection is made, and the selection has
  // a display image, it is used
  useEffect(() => {
    const updateDisplayImage = async () => {
      const selection = selectionID
        ? option.selections.find(s => s.catalogOptionID === selectionID)
        : null;
      const displayImage = selection?.displayImage || option.displayImage;
      const buffer = await getImageBuffer(displayImage, 0);
      setDisplayImageBuffer(buffer);
    };

    updateDisplayImage();
  }, [option, selectionID]);

  const showCompareBtn = useMemo(
    () =>
      option.type === 'selection' && option.selections.some(s => s.description || s.displayImage),
    [option],
  );

  /**
   * Saves the provided selection ID for all option types and applies the visitor's
   * selection for that option
   * is either a selection + option or undefined
   */
  const handleSelection = (selection: OptionForSelectionOption | null, value?: string) => {
    // if we opt out  - explicitly pass back null value for selection to optout of the option
    if (!selection) {
      setSelectionID(false);
      return;
    }

    // otherwise user has opted into an option selection
    setSelectionID(selection.catalogOptionID, value);
  };

  const handleOptional = () => {
    setOpenOptional(true);

    // if it is a toggle or text
    // auto set the selection to the only available selection
    // because they are actively "opting in" regardless of "defaultSelectionID"
    // for selections we use the defaultID (which should be set already from parent)
    if (option.type === 'boolean' || option.type === 'text') {
      setSelectionID(option.selections[0].catalogOptionID);
    }

    // OPTIONAL TOGGLE IMAGE OPTION ONLY
    // Currently only applies to toggle image options where we want to callback to parent and pop a modal
    // when there are multiple image nodes for a optional toggle option
    // later on it may be beneficial to refactor to image vs product vs order options
    // that each explicitly call either one of the toggle, selection vs text options
    // and handle themselves
    const isSingleSelectionOption =
      !showRequired && option.type === 'selection' && option.selections.length === 1;
    if (optionType === 'image' && (option.type === 'boolean' || isSingleSelectionOption)) {
      setSelectionID(option.selections[0].catalogOptionID);
    }
  };

  const renderInputByType = () => {
    if (option.type === 'selection') {
      return (
        <SelectionOption
          isInvalid={isInvalid}
          onSelect={handleSelection}
          option={option}
          selectionBadges={selectionBadges}
          selectionID={selectionID}
        />
      );
    }
    if (option.type === 'boolean') {
      return (
        <ToggleOption
          isInvalid={isInvalid}
          onSelect={handleSelection}
          option={option}
          selectionBadges={selectionBadges}
          selectionID={selectionID}
        />
      );
    }
    if (option.type === 'text') {
      return (
        <TextOption
          catalogProductID={catalogProductID}
          defaultText={defaultText}
          focused={focused}
          hideSave={hideTextSave}
          isInvalid={isInvalid}
          onSelect={handleSelection}
          option={option}
          selectionID={selectionID}
        />
      );
    }
  };

  const renderCompareButton = () => {
    if (!showCompareBtn) {
      return;
    }

    return (
      <Button
        color="brand"
        fontSize="sm"
        marginTop={0}
        onClick={() => setShowCompare(!showCompare)}
        variant="link"
      >
        {compare}
      </Button>
    );
  };

  const renderDisplayImage = () => (
    <Box width={compact ? '100px' : '200px'} maxHeight="100%">
      <ProductImage
        fallbackFontSize="12px"
        fallbackIconSize="34px"
        image={displayImageBuffer as string}
      />
    </Box>
  );

  const renderPriceRange = () => {
    if (option.type === 'selection' && option.selectionPricing) {
      // Selection pricing means we need to check for a range:
      const prices = option.selections.map(s => s.price);
      const min = Math.min(...prices);
      const max = Math.max(...prices);

      return (
        <Text data-test={`${heading}-price`} fontSize="md" marginBottom={2}>
          {min ? formatCurrency(min, currency) : FREE}
          {min !== max && `-${formatCurrency(max, currency)}`}
        </Text>
      );
    }

    // Otherwise return price on the option
    return (
      <Text data-test={`${heading}-price`} fontSize="md" marginBottom={2}>
        {option.price > 0 ? formatCurrency(option.price, currency) : FREE}
      </Text>
    );
  };

  const renderDescription = () => {
    if (!option.description) {
      return;
    }

    const truncated = truncate
      ? `${option.description.slice(0, truncate).trimEnd()}…`
      : option.description;

    return (
      <Text marginBottom={2}>
        {truncated}
        {!!truncate && (
          <Button
            as="span"
            margin={0}
            onClick={() => setTruncate(0)}
            paddingLeft={2}
            size="sm"
            variant="link"
          >
            {showMore}
          </Button>
        )}
      </Text>
    );
  };

  const dataCy = heading
    ? heading.replace(/\s+/g, '-').toLowerCase() + '-add-on'
    : 'label-not-present';
  const renderOptionalAddButton = () => (
    <Button
      variant="linkGreen"
      marginY={0}
      fontWeight="bold"
      onClick={handleOptional}
      data-test={dataCy}
    >
      {greenText}
    </Button>
  );

  return (
    <>
      <Flex
        data-test={
          heading
            ? heading.replace(/\s+/g, '-').toLowerCase() + '-container'
            : 'label-not-present-container'
        }
        key={option.id}
        direction="column"
        marginBottom={{ base: 8, md: compact ? 8 : 4 }}
      >
        <Flex
          alignItems={compact ? 'flex-start' : 'center'}
          direction={compact ? 'row' : 'column'}
          marginBottom={compact ? 4 : 0}
        >
          {renderDisplayImage()}
          <Flex
            alignItems="flex-start"
            direction="column"
            flex={1}
            justifyContent={compact ? 'flex-start' : 'space-between'}
            marginTop={compact ? 0 : 4}
            paddingLeft={compact ? 4 : 0}
            width="100%"
          >
            <Flex justifyContent="space-between" width="100%">
              <Heading color="black" fontSize={isRequired ? 'lg' : 'md'} marginBottom={2}>
                {heading} {showRequired && <chakra.span color="error">*</chakra.span>}
              </Heading>
              {!compact && renderCompareButton()}
            </Flex>
            {renderPriceRange()}
            {compact && renderCompareButton()}
            {renderDescription()}
            {!isRequired && optionType !== 'order' && renderOptionalAddButton()}
          </Flex>
        </Flex>
        {(isRequired || optionType === 'order' || openOptional || expanded) && (
          <Flex width="100%" wrap="wrap">
            {renderInputByType()}
            {isInvalid && option.type !== 'text' && (
              <Text
                color="error"
                data-test="required-text-make-a-selection"
                fontSize="xs"
                paddingLeft={1}
                textAlign="left"
                width="100%"
              >
                {pleaseMakeASelection}
              </Text>
            )}
          </Flex>
        )}
      </Flex>
      {option.type === 'selection' && (
        <CompareChoices
          isOpen={showCompare}
          onClose={() => setShowCompare(!showCompare)}
          option={option}
        />
      )}
    </>
  );
};

export default Option;
