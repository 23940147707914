// TODO: Make the following responsive for mobile

import { Button, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FiCamera } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AccountGallery } from '../../../shop-api-client/models/AccountGallery';
import { selectGalleryMap } from '../../redux/selectors/gallery.selectors';
import { setGalleries } from '../../redux/slices/account.slice';
import { useAppDispatch } from '../../redux/store';
import Logo from '../components/Logo';
import Tag from '../components/Tag';
import { FADE_IN_KEYFRAMES } from '../constants/style.constants';
import { Params } from '../types/router';
import { pluralize } from '../utils';

const EXPIRATION_CAP = 14;
const NEW_GALLERY_CAP = 14;

interface Props {
  gallery: AccountGallery;
  multiGallery?: boolean;
}

const GalleryCard = ({ gallery, multiGallery }: Props) => {
  const galleryMap = useSelector(selectGalleryMap);
  const { key } = useParams<Params>();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const history = useHistory();

  // Tracks gallery dates for use with Tags
  const current = moment(new Date());
  const galleryEventDate = new Date(gallery.eventDate);
  galleryEventDate.setUTCHours(11, 59, 59, 999);
  const eventDate = moment(galleryEventDate);
  const expirationDate = moment(gallery.expirationDate);
  const daysUntilExpiration = Math.ceil(moment.duration(expirationDate.diff(current)).asDays());
  const visitKey = gallery.subjectID ? `s${gallery.subjectID}` : `g${gallery.id}`;

  const activeSession = useMemo(() => {
    if (gallery.type === 'standard') {
      return galleryMap[`g${gallery.id}`];
    }

    return Object.values(galleryMap).find(
      g => gallery.subjectID && g.subjectID === gallery.subjectID,
    );
  }, [gallery, galleryMap]);

  const expirationTagText = intl.formatMessage(
    {
      id: 'accountLanding.expiresInDays',
      defaultMessage: 'Expires in {days}',
    },
    { days: pluralize(daysUntilExpiration, 'day', true) },
  );
  const expiredText = intl.formatMessage({
    id: 'accountLanding.expired',
    defaultMessage: 'Expired',
  });
  const preorderNowText = intl.formatMessage({
    id: 'accountLanding.preorderNow',
    defaultMessage: 'Preorder Now',
  });
  const visitGalleryText = intl.formatMessage({
    id: 'accountLanding.visitGallery',
    defaultMessage: 'Visit Gallery',
  });

  const onClick = () => {
    dispatch(setGalleries([]));
    if (activeSession) {
      history.push(`/${visitKey}/${gallery.isPreOrder ? 'shop' : 'photos'}`, {
        prevPath: pathname,
      });
    } else {
      history.push(multiGallery ? `/${key}/galleries/${visitKey}/login` : `/${visitKey}`, {
        prevPath: pathname,
      });
    }
  };

  const renderWelcomeImage = () => (
    // If no welcome image, display fallback image
    <Logo
      alt="Welcome Image"
      fallback={
        <Icon
          as={FiCamera}
          color="grey.3"
          data-test="gallery-card-welcome-image"
          fontSize="45px"
          marginTop={3}
        />
      }
      imageSrc={gallery.welcomeImage}
      maxHeight={{ base: '55px', md: '85px' }}
      maxWidth="85px"
    />
  );

  return (
    <Flex
      data-test="gallery-card"
      animation={`${FADE_IN_KEYFRAMES} 1s`}
      borderColor="grey.2"
      borderRadius={8}
      borderWidth="1px"
      direction={{ base: 'column', md: 'row' }}
      marginY={2}
      maxWidth="1000px"
      minHeight="130px"
      padding="20px 20px 30px 25px"
      position="relative"
      width="100%"
    >
      <Text color="grey.5" fontSize="xs" position="absolute" right={3}>
        {eventDate.fromNow()}
      </Text>
      <Flex
        align="start"
        justify={{ base: 'left', md: 'center' }}
        paddingBottom={3}
        paddingRight="25px"
        width="120px"
      >
        {renderWelcomeImage()}
      </Flex>
      <Flex justify="space-between" width="100%">
        <Flex direction="column">
          <Heading size="sm" data-test="gallery-card-title">
            {gallery.title}
          </Heading>
          <Text marginTop={1} fontSize="sm">
            {eventDate.format('MMMM Do, YYYY')}
          </Text>
          <Flex marginTop={4}>
            {moment.duration(current.diff(eventDate)).asDays() <= NEW_GALLERY_CAP && (
              <Tag
                backgroundColor="green.1"
                color="green.7"
                data-test="gallery-card-new-tag"
                text="New"
              />
            )}
            {gallery.isPreOrder && <Tag text="Preorder" data-test="gallery-card-preorder-tag" />}
            {!gallery.isPreOrder && gallery.status === 'active' && gallery.numImages > 0 && (
              <Tag
                backgroundColor="blue.1"
                color="blue.7"
                data-test="gallery-card-image-ready-tag"
                text="Images Ready"
              />
            )}
            {daysUntilExpiration <= EXPIRATION_CAP && (
              <Tag
                backgroundColor="red.1"
                color="red.7"
                data-test="gallery-card-expiration-tag"
                text={daysUntilExpiration < 0 ? expiredText : expirationTagText}
              />
            )}
          </Flex>
        </Flex>
        <Flex alignItems="flex-end">
          <Button
            data-test="gallery-card-link-button"
            fontSize="sm"
            height="45px"
            onClick={onClick}
            paddingY={4}
            variant="primary"
            width={{ base: '100%', sm: '120px' }}
          >
            {gallery.isPreOrder ? preorderNowText : visitGalleryText}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default GalleryCard;
