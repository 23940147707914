import { CheckIcon } from '@chakra-ui/icons';
import { Alert, Box, Button, Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ShopImage } from '../../../shop-api-client';
import { selectGallery } from '../../redux/selectors/gallery.selectors';
import ImageWithFallback from '../../shared/components/ImageWithFallback';
import { GRID_MAX_WIDTH } from '../../shared/constants';
import useWindowSize from '../../shared/hooks/useWindowSize';
import { Params } from '../../shared/types/router';
import { convertToLocalTimeZone, getShopAllPath } from '../../shared/utils';
import YearbookTimer from './YearbookTimer';
import { hasDueDatePassed } from './yearbook.utils';

const Yearbook = () => {
  // Redux Selectors
  const {
    images,
    isPreOrder,
    yearbookDueDate,
    yearbookMessage,
    yearbookPoses,
    yearbookSelections,
  } = useSelector(selectGallery);
  const isPastDueDate = hasDueDatePassed(yearbookDueDate);

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [poseOne, setPoseOne] = useState<ShopImage>();
  const [poseTwo, setPoseTwo] = useState<ShopImage>();

  // Hooks
  const { key } = useParams<Params>();
  const { url } = useRouteMatch();
  const { width } = useWindowSize();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const layout = useBreakpointValue({
    base: 'column-reverse nowrap',
    md: 'column-reverse nowrap',
    lg: 'row nowrap',
  });

  useEffect(() => {
    if (isPreOrder) {
      const shopAllPath = getShopAllPath(key);
      history.replace(shopAllPath);
    }
  }, [isPreOrder, history, key]);

  // Initialize + setup primary/secondary poses
  useEffect(() => {
    if (!isLoading) {
      return;
    }

    const init = () => {
      // If no selections, return
      if (!yearbookSelections) {
        return;
      }

      if (yearbookSelections.yearbookSelection1) {
        setPoseOne(images[yearbookSelections.yearbookSelection1]);
      }

      if (yearbookSelections.yearbookSelection2) {
        setPoseTwo(images[yearbookSelections.yearbookSelection2]);
      }
    };

    init();
    setIsLoading(false);
  }, [images, isLoading, yearbookSelections]);

  const renderFooter = () => {
    const isInitialSelection =
      yearbookPoses &&
      (yearbookPoses === 2
        ? yearbookSelections?.yearbookSelection1 && yearbookSelections.yearbookSelection2
        : yearbookSelections?.yearbookSelection1);

    return (
      <Flex
        bgColor={isMobile ? 'white' : 'transparent'}
        borderColor={isMobile ? 'grey.2' : 'transparent'}
        borderTop={isMobile ? '1px solid' : 'transparent'}
        bottom="0"
        justifyContent={isMobile ? 'center' : 'flex-end'}
        left="0"
        margin="0 auto"
        padding={0}
        paddingY={2}
        position={isMobile ? 'fixed' : 'relative'}
        width={isMobile ? '100vw' : '100%'}
      >
        <Link to={`${url}/pose1`}>
          <Button
            bgColor={isInitialSelection ? 'grey.3' : ''}
            color={isInitialSelection ? 'black' : 'white'}
            variant="primary"
            width={isMobile ? '85vw' : '100%'}
            data-test="yearbook-select-photos"
          >
            {intl.formatMessage(
              { id: 'yearbook.selectButton', defaultMessage: '{ChangeSelect} {photo}' },
              {
                photo: yearbookPoses && yearbookPoses > 1 ? 'Photos' : 'Photo',
                ChangeSelect: !isInitialSelection ? 'Select' : 'Change',
              },
            )}
          </Button>
        </Link>
      </Flex>
    );
  };

  // Render each primary/secondary pose
  const renderImage = (pose: ShopImage | undefined, poseType: string) => (
    <Flex
      direction="column"
      justifyContent="center"
      marginTop={8}
      marginX={isMobile ? 1 : 4}
      maxW={width / 2}
    >
      <Heading fontSize="sm" textTransform="uppercase">
        {intl.formatMessage(
          { id: 'yearbook.poseTitle', defaultMessage: 'Pose #{poseType}' },
          { poseType },
        )}
      </Heading>
      <Flex alignItems="center" justifyContent="center" maxW={width} position="relative">
        <ImageWithFallback
          aspectRatio={1}
          bgColor="transparent"
          fallbackText="Not Selected"
          height={`${isMobile ? width / 2.5 : width / 5}px`}
          image={pose?.sources.thumb || ''}
          margin={0}
          width={`${isMobile ? width / 2.5 : width / 5}px`}
          data-test={`yearbook-pose-${poseType}`}
        />
      </Flex>

      <Text
        align="center"
        fontSize="md"
        noOfLines={1}
        maxW={`${isMobile ? width / 2.5 : width / 5}px`}
        width="100%"
        data-test="yearbook-image-text"
      >
        {pose?.displayName || ''}
      </Text>
    </Flex>
  );

  if (isLoading) {
    return null;
  }

  return (
    <Flex maxWidth={GRID_MAX_WIDTH} width="100%" margin="0 auto">
      <Flex
        flexFlow="column"
        justifyContent="flex-start"
        margin="0 auto"
        marginTop={2}
        minH={isMobile ? '100vh' : '100%'}
        width="80%"
      >
        <Heading my={4} fontSize="2xl">
          {intl.formatMessage({
            id: 'yearbook.mainHeader',
            defaultMessage: 'Choose Yearbook Pose',
          })}
        </Heading>
        {!!yearbookDueDate && <YearbookTimer />}

        <Flex flexFlow={layout} width="100%">
          <Flex
            alignItems="flex-start"
            flexFlow="column"
            marginTop={6}
            width={isMobile ? '100%' : '30%'}
          >
            <Flex flexFlow="column" width={isMobile ? '100%' : '100%'}>
              <Heading paddingY="2" size="md">
                {intl.formatMessage({
                  id: 'yearbook.instructions',
                  defaultMessage: 'Instructions',
                })}
              </Heading>
              <Box marginBottom={4}>
                <Text whiteSpace="pre-line" data-test="yearbook-default-message">
                  {intl.formatMessage({
                    id: 'yearbook.defaultMessage',
                    defaultMessage: 'Use the button below to start selecting your images.',
                  })}
                </Text>
                {yearbookMessage && <Text marginTop={2}>{yearbookMessage}</Text>}
              </Box>

              {!!yearbookSelections?.yearbookSelection1 &&
                !!yearbookSelections.yearbookSelection2 && (
                  <Alert
                    backgroundColor="white"
                    borderRadius="3xl"
                    fontWeight="bold"
                    marginLeft={0}
                    paddingLeft={0}
                    variant="success"
                    width="100%"
                    data-test="yearbook-alert"
                  >
                    <CheckIcon m={3} />
                    {yearbookSelections.lastModifiedDate &&
                      intl.formatMessage(
                        {
                          id: 'yearbook.mainLastSaved',
                          defaultMessage: 'Yearbook selections last saved on: {lastSaved}',
                        },
                        {
                          lastSaved: convertToLocalTimeZone(
                            new Date(yearbookSelections.lastModifiedDate),
                          ),
                        },
                      )}
                  </Alert>
                )}
            </Flex>
            {!isPastDueDate && renderFooter()}
          </Flex>

          <Flex flex="1" justifyContent="center">
            {renderImage(poseOne, '1')}
            {yearbookPoses && yearbookPoses > 1 && renderImage(poseTwo, '2')}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Yearbook;
