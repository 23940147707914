import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectGallery } from '../../redux/selectors/gallery.selectors';
import { selectBuyModeImage } from '../../redux/selectors/interactions.selectors';
import { useAppDispatch } from '../../redux/store';
import { initializeSelectAndBuy } from '../../redux/thunks/interactions.thunks';
import useSearchParams from './useSearchParams';

export const useStickySelectAndBuyParams = () => {
  const { getParamsValue } = useSearchParams();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { images } = useSelector(selectGallery);
  const { buyModeBackground, buyModeImage } = useSelector(selectBuyModeImage);

  const bgFromParams = getParamsValue('bg');
  const imgFromParams = getParamsValue('image');

  useEffect(() => {
    if (!buyModeImage && imgFromParams) {
      const image = images[imgFromParams];
      dispatch(initializeSelectAndBuy(imgFromParams, parseInt(bgFromParams)));
      if (!image) {
        history.replace({ search: '' });
      }
    } else if (buyModeImage && !imgFromParams) {
      const params = new URLSearchParams(search);
      params.set('image', buyModeImage.internalName);
      if (buyModeBackground) {
        params.set('bg', buyModeBackground.id.toString());
      } else {
        params.delete('bg');
      }
      history.replace({ search: '?' + params.toString() });
    }
  }, [
    bgFromParams,
    buyModeBackground,
    buyModeImage,
    dispatch,
    history,
    images,
    imgFromParams,
    search,
  ]);
};
