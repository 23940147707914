import { Button, Flex, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import ArrowLeftbutton from '../../../../../../../shared/components/ArrowLeftButton';
import { Z_INDEX_ONE } from '../../../../../../../shared/constants';
import { PREVIEW, SAVE_AND_CONTINUE } from '../../../../constants';

interface Props {
  handleBack(): void;
  handlePreview(): void;
  handleSave(): void;
}

const MultiNodeHeader = ({ handleBack, handlePreview, handleSave }: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex
      backgroundColor="white"
      borderBottomColor="grey.2"
      borderBottomStyle="solid"
      borderBottomWidth="1px"
      direction="row"
      height="58px"
      padding="4px"
      position="fixed"
      width="100%"
      zIndex={Z_INDEX_ONE}
    >
      <ArrowLeftbutton
        alignSelf="center"
        aria-label="Back"
        border="none"
        fontSize="md"
        lightMode
        onClick={handleBack}
        size="sm"
      />
      {!isMobile && (
        <Button
          color="brand"
          marginLeft="auto"
          marginRight="60px"
          onClick={handlePreview}
          variant="link"
        >
          {PREVIEW}
        </Button>
      )}
      <Button marginLeft={isMobile ? 'auto' : undefined} onClick={handleSave}>
        {SAVE_AND_CONTINUE}
      </Button>
    </Flex>
  );
};

export default MultiNodeHeader;
